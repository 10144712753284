import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

export default function PostDetailModal({ open, onClose, onConfirm, type, setOTP, OTP }) {
    const renderDialogContent = () => {
        switch (type) {
            case 'deliver':
                return (
                    <>
                        <p>Teslim etmek için OTP kodunu giriniz.</p>
                        <TextField label="OTP Kodu" variant="outlined" fullWidth value={OTP} onChange={e => setOTP(e.target.value)} />
                    </>
                );
            case 'take':
                return <p>Teslim almak istediğinize emin misiniz?</p>;
            case 'return':
                return <p>İade etmek istediğinize emin misiniz?</p>;
            case 'setInBranch':
                return <p>Zimmete almak istediğinize emin misiniz?</p>;
            default:
                return null;
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>İşlem Onayı</DialogTitle>
            <DialogContent>
                {renderDialogContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Kapat
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Onayla
                </Button>
            </DialogActions>
        </Dialog>
    );
}