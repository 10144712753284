import {
    Card,
    Stack,
    Typography,
    Divider
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'src/services/BaseService';
import QuestionAnalyzer from 'src/components/survey/QuestionAnalyzer';
import { useLocalization } from 'src/context/LocalizationContext';

//import Services from 'src/services/Services'; 

const SurveyResponses = () => {
    const { language } = useLocalization();
    const [surveyList, setSurveyList] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    //const [userData, setUserData] = useState([]);

    const { id } = useParams();

    const fetchSurveyAndUsers = async () => {
        try {
            const surveyResponse = await api.post('flow/get-survey-responses', { surveyID: id });
            if (surveyResponse.data.status) {
                setOriginalData(surveyResponse.data.result);
                setSurveyList(surveyResponse.data.result);
            }
        } catch (error) {
            console.error("Error fetching survey or user data:", error);
        }
    };

    useEffect(() => {
        fetchSurveyAndUsers();
    }, []);


    return (
        <Stack style={{ paddingInline: '25px' }}>

            <Card sx={{ mb: 3, p: 2 }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    {language.commons.surveys.survey_results}
                </Typography>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <QuestionAnalyzer responses={surveyList} />
            </Card>

        </Stack>
    );
};

export default SurveyResponses;
