import { Navigate, useLocation } from 'react-router-dom';
import { tokenService } from '../../services';

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  if (!tokenService.getUser()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
