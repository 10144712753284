import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Stack,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  Divider,
  CircularProgress,
  DialogActions,
  Tooltip
} from '@mui/material';
// sections
import AddEditPointModal from 'src/modals/AddEditPointModal';
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
// shared
import AddEntityButton from 'src/components/shared/AddEntityButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import EditButton from 'src/components/shared/EditButton';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import './pointsPage.css';
import MobileFilterButton from 'src/components/shared/MobileFilterButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import StatusFilters from 'src/components/points/StatusFilter';
import { check } from 'prettier';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Iconify from 'src/components/iconify/Iconify';
// ----------------------------------------------------------------------

export default function PointsPage() {
  const customer = useSelector((state) => state.customerReducer);
  const [orderList, setOrderList] = useState([]);
  const [locations, setLocations] = useState([]);

  const [addEditPointOpen, setAddEditPointOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState();

  const [filterTags, setFilterTags] = useState([]);

  const [originalData, setOriginalData] = useState([]);
  const [title, setTitle] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [isAmazon, setIsAmazon] = useState('');
  const [isTrendyol, setIsTrendyol] = useState('');
  const [isHepsiburada, setIsHepsiburada] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [isActive, setIsActive] = useState('');
  const [malls, setMalls] = useState([]);
  const [userList, setUserList] = useState([]);
  const minWidth768 = useMediaQuery('(min-width:768px)');
  const [mobileFilterModal, setMobileFilterModal] = useState(false);

  const [temporaryPassivePoints, setTemporaryPassivePoints] = useState([]);
  const [passivePoints, setPassivePoints] = useState([]);
  const [activePoints, setActivePoints] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('1');
  const [showPopUpError, setShowPopUpError] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState();
  const [loading, setLoading] = useState(false);
  const [qr, setQr] = useState('');
  const [open, setOpen] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };
  const handleOpenAddEditPointModal = () => {
    setAddEditPointOpen(true);
    setIsModalEdit(false);
  };

  const handleCloseAddEditPointModal = () => {
    setAddEditPointOpen(false);
    setEditData();
    getPoints();
    getDirections();
  };

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setAddEditPointOpen(true);
    setIsModalEdit(true);
  };

  const handleCopy = (branchCode) => {
    navigator.clipboard.writeText(branchCode)
  };

  let TABLE_HEAD = [
    { id: 'title', label: 'Nokta Adı', alignRight: false, width: 15 },
    {
      id: 'branchCode',
      label: 'Şube Kodu',
      alignRight: false,
      format: (value, row) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '50px' }}>
          <span style={{ marginRight: '6px' }}>{row.branchCode}</span>
          {row.branchCode && row.branchCode !== "NA" &&
            <ContentCopyIcon
              style={{ cursor: 'pointer', color: '#98A2B3' }}
              onClick={() => handleCopy(row.branchCode)}
            />
          }

        </div>
      )
    },
    {
      id: 'type',
      label: 'Türü',
      alignRight: false,
      width: 15,
      format: (value, row) => {
        let typeString = '';

        if (row.isBox === 1) {
          typeString += 'Express - ';
        }
        if (row.isEsnaf === '1') {
          typeString += 'Esnaf - ';
        }
        if (row.isEasyPoint === '1') {
          typeString += 'Easy Point - ';
        }
        if (row.isMalKabul === '1') {
          typeString += 'Mal Kabul - ';
        }
        if (row.isMuhaberat === '1') {
          typeString += 'Muhaberat - ';
        }

        if (typeString.endsWith(' - ')) {
          typeString = typeString.slice(0, -3);
        }

        return typeString;
      },
      sx: { width: '15%' },
    },
    {
      id: 'pointTags',
      label: 'Nokta Tagleri',
      alignRight: false,
      width: 5,
      format: (value, row) => (
        <div className="mobile_point_tag">
          {row.isAmazon === '1' && (
            <span
              style={{
                backgroundColor: '#F3F4F6',
                padding: '4px 12px 4px 10px',
                borderRadius: '5px',
                textAlign: 'center',
                border: '1px solid #D0D5DD',
                color: '#6B7280',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Inter',
                marginRight: '6px',
              }}
            >
              Amz
            </span>
          )}
          {row.isHepsiburada === '1' && (
            <span
              style={{
                backgroundColor: '#FFF4ED',
                padding: '4px 12px 4px 10px',
                borderRadius: '5px',
                textAlign: 'center',
                border: '1px solid #FFD6AE',
                color: '#FF9C66',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Inter',
                marginRight: '6px',
              }}
            >
              H.B
            </span>
          )}
          {row.isTrendyol === '1' && (
            <span
              style={{
                backgroundColor: '#FFF4ED',
                padding: '4px 12px 4px 10px',
                borderRadius: '5px',
                textAlign: 'center',
                border: '1px solid #FFD6AE',
                color: '#6B7280',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Inter',
                marginRight: '6px',
              }}
            >
              Trendyol
            </span>
          )}
        </div>
      ),
      sx: { width: '5%' },
    },
    {
      id: 'cityAndRegion',
      label: 'İl/İlçe',
      alignRight: false,
      sortBy: 'city',
      width: 15,
      format: (value, row) => (
        <span>
          {row.city}/{row.district}
        </span>
      ),
      sx: { width: '15%' },
    },
    {
      id: 'state',
      label: 'Durum',
      alignRight: false,
      width: 15,
      format: (value, row) => {
        if (checkIsTempPassive(row)) {
          return (
            <span
              style={{
                backgroundColor: '#FFFAEB',
                padding: '4px 12px 4px 10px',
                borderRadius: '5px',
                textAlign: 'center',
                border: '1px solid #FEDF89',
                color: '#B54708',
                fontSize: 14,
                fontWeight: 500,
                fontFamily: 'Inter',
                marginRight: '6px',
              }}
            >
              ● Geçici Pasif
            </span>
          );
        } else {
          return (
            <span>
              {row.status === '1' ? (
                <span
                  style={{
                    backgroundColor: '#ECFDF3',
                    padding: '4px 12px 4px 10px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    border: '1px solid #ABEFC6',
                    color: '#067647',
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    marginRight: '6px',
                  }}
                >
                  ● Aktif
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: '#F9FAFB',
                    padding: '4px 12px 4px 10px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    border: '1px solid #EAECF0',
                    color: '#344054',
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    marginRight: '6px',
                  }}
                >
                  ● Pasif
                </span>
              )}
            </span>
          );
        }
      },
      sx: { width: '15%' },
    },
    {
      id: 'qrIcon', label: '', alignRight: false, format: (value, row) =>
        <Tooltip title={"QR Kodu"}>
          <Iconify
            style={{
              color: '#98A2B3',
              width: 22,
              height: 22,
              cursor: 'pointer',
            }}
            icon="bi:qr-code"
            onClick={() => handleQRCodeClick(row)}
          />
        </Tooltip>
    },
    {
      id: 'operation',
      label: '',
      alignRight: false,
      format: (value, row) => {
        return <EditButton onClick={() => handleEdit(row)} />;
      },
      sx: { width: '5%' },
      width: 5,
    },
  ];

  const handleDownload = () => {
    saveAs(qr, `${selectedPoint.title}_qr.jpg`);
  };


  const handleCloseDialog = () => {
    setOpen(false);
    setQr('');
  };

  const handleQRCodeClick = (point) => {
    setLoading(true);
    getQR(point.id);
    setSelectedPoint(point);
  };

  function getQR(pointID) {
    api
      .post('easypoint-centers/get-static-qr', {
        pointID: pointID,
      })
      .then((response) => {
        if (response.data.status) {
          setOpen(true);
          setQr(response.data.result);
        } else {
          toast.error('QR değeri çekilirken bir hata meydana geldi.');
        }
      })
      .catch(() => toast.error('QR değeri çekilirken bir hata meydana geldi.'))
      .finally(() => setLoading(false));
  }

  const getFilteredPointList = (statusFilter) => {
    let filterForStatus = statusFilter ? statusFilter : selectedStatusFilter;

    if (filterForStatus === '1') {
      // Step 1: Filter based on tags
      let filteredData =
        filterTags.length > 0
          ? activePoints.filter((item) => {
            let isMatch = true;

            for (let tag of filterTags) {
              if (item[tag] !== '1') {
                isMatch = false;
                break;
              }
            }

            if (
              (!filterTags.includes('isAmazon') && item.isAmazon !== '0') ||
              (!filterTags.includes('isHepsiburada') &&
                item.isHepsiburada !== '0') ||
              (!filterTags.includes('isTrendyol') && item.isTrendyol !== '0')
            ) {
              isMatch = false;
            }

            return isMatch;
          })
          : activePoints;

      // Step 2: Filter based on other input criteria
      if (
        title ||
        branchCode ||
        isAmazon ||
        isTrendyol ||
        isHepsiburada ||
        city ||
        region ||
        isActive
      ) {
        filteredData = filteredData.filter((item) => {
          const titleMatch = title
            ? item?.title?.toLowerCase().includes(title.toLowerCase())
            : true;
          const branchCodeMatch = branchCode
            ? item?.branchCode?.toLowerCase().includes(branchCode.toLowerCase())
            : true;
          const cityMatch = city
            ? item?.city?.toLowerCase().includes(city.toLowerCase())
            : true;
          const regionMatch = region
            ? item?.region?.toLowerCase().includes(region.toLowerCase())
            : true;
          const isActiveMatch = isActive ? item?.status === isActive : true;
          return (
            titleMatch &&
            branchCodeMatch &&
            cityMatch &&
            regionMatch &&
            isActiveMatch
          );
        });
      }

      setOrderList(filteredData);
    } else if (filterForStatus === '0') {
      // Step 1: Filter based on tags
      let filteredData =
        filterTags.length > 0
          ? passivePoints.filter((item) => {
            let isMatch = true;

            for (let tag of filterTags) {
              if (item[tag] !== '1') {
                isMatch = false;
                break;
              }
            }

            if (
              (!filterTags.includes('isAmazon') && item.isAmazon !== '0') ||
              (!filterTags.includes('isHepsiburada') &&
                item.isHepsiburada !== '0') ||
              (!filterTags.includes('isTrendyol') && item.isTrendyol !== '0')
            ) {
              isMatch = false;
            }

            return isMatch;
          })
          : passivePoints;

      // Step 2: Filter based on other input criteria
      if (
        title ||
        branchCode ||
        isAmazon ||
        isTrendyol ||
        isHepsiburada ||
        city ||
        region ||
        isActive
      ) {
        filteredData = filteredData.filter((item) => {
          const titleMatch = title
            ? item.title.toLowerCase().includes(title.toLowerCase())
            : true;
          const branchCodeMatch = branchCode
            ? item.branchCode.toLowerCase().includes(branchCode.toLowerCase())
            : true;
          const cityMatch = city
            ? item.city.toLowerCase().includes(city.toLowerCase())
            : true;
          const regionMatch = region
            ? item.region.toLowerCase().includes(region.toLowerCase())
            : true;
          const isActiveMatch = isActive ? item.status === isActive : true;
          return (
            titleMatch &&
            branchCodeMatch &&
            cityMatch &&
            regionMatch &&
            isActiveMatch
          );
        });
      }

      setOrderList(filteredData);
    } else if (filterForStatus === '3') {
      // Step 1: Filter based on tags
      let filteredData =
        filterTags.length > 0
          ? temporaryPassivePoints.filter((item) => {
            let isMatch = true;

            for (let tag of filterTags) {
              if (item[tag] !== '1') {
                isMatch = false;
                break;
              }
            }

            if (
              (!filterTags.includes('isAmazon') && item.isAmazon !== '0') ||
              (!filterTags.includes('isHepsiburada') &&
                item.isHepsiburada !== '0') ||
              (!filterTags.includes('isTrendyol') && item.isTrendyol !== '0')
            ) {
              isMatch = false;
            }

            return isMatch;
          })
          : temporaryPassivePoints;

      // Step 2: Filter based on other input criteria
      if (
        title ||
        branchCode ||
        isAmazon ||
        isTrendyol ||
        isHepsiburada ||
        city ||
        region ||
        isActive
      ) {
        filteredData = filteredData.filter((item) => {
          const titleMatch = title
            ? item.title.toLowerCase().includes(title.toLowerCase())
            : true;
          const branchCodeMatch = branchCode
            ? item.branchCode.toLowerCase().includes(branchCode.toLowerCase())
            : true;
          const cityMatch = city
            ? item.city.toLowerCase().includes(city.toLowerCase())
            : true;
          const regionMatch = region
            ? item.region.toLowerCase().includes(region.toLowerCase())
            : true;
          const isActiveMatch = isActive ? item.status === isActive : true;
          return (
            titleMatch &&
            branchCodeMatch &&
            cityMatch &&
            regionMatch &&
            isActiveMatch
          );
        });
      }

      setOrderList(filteredData);
    }
  };

  const getFilteredPointListByTag = (tag) => {
    if (filterTags.includes(tag)) {
      setFilterTags(filterTags.filter((existingTag) => existingTag !== tag));
    } else {
      setFilterTags([...filterTags, tag]);
    }
  };

  useEffect(() => {
    getFilteredPointList();
  }, [filterTags, originalData]);

  function checkIsTempPassive(point) {
    if (point.isTemporaryPassive) {
      return true;
    } else return false;
  }

  const clearFilters = () => {
    setOrderList(originalData);
    setTitle('');
    setBranchCode('');
    setIsAmazon('');
    setIsTrendyol('');
    setIsHepsiburada('');
    setFilterTags([]);
    setCity('');
    setRegion('');
    setIsActive('');
    setSelectedStatusFilter('1');
  };

  function exportToExcel() {
    let temp1 = orderList.map((x, index) => {
      let a = {
        id: index + 1,
        title: x.title,
        branchCode: x.branchCode,
        city: x.city,
        region: x.district,
        status: x.status === '1' ? 'Aktif' : 'Pasif',
      };
      return a;
    });

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          No: 'number',
          'Nokta Adı': 'string',
          'Şube Kodu': 'string',
          İl: 'string',
          İlçe: 'string',
          Durum: 'string',
        },
        sheetTitle: 'Noktalar',
      })
      .then((response) => {
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
              setShowPopUpError(true)
              throw new Error('Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.');
            }
          } catch (e) {
            setShowPopUpError(true)
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  // Service
  function getLocations(directions) {
    api
      .post('locations/get-locations', {
        branchID: customer?.customer?.branchID,
      })
      .then((response) => {
        if (response.data.status) {
          const permutations = [];

          for (let i = 0; i < response.data.result?.length; i++) {
            for (let j = 0; j < response.data.result?.length; j++) {
              if (i !== j) {
                permutations.push({
                  title: `${response.data.result[i].title} → ${response.data.result[j].title}`,
                  handle: 'StoreToStore',
                  type: 'new',
                  from: response.data.result[i].locationID,
                  to: response.data.result[j].locationID,
                });
              }
            }
          }

          directions = directions.concat(permutations);
          setLocations(directions);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  function getDirections() {
    api
      .get('directions/get')
      .then((response) => {
        if (response.data.status) {
          getLocations(
            response.data.result.filter((x) => x.handle != 'StoreToStore')
          );
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  function getUsers() {
    api
      .post('users/get-users', {
        status: true,
      })
      .then((response) => {
        if (response.data.status) {
          setUserList(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  function getTemporaryPassiveList(allData) {
    const currentDate = new Date();

    // temporaryPassive array'ini oluşturacağız
    const temporaryPassive = [];

    // allData array'ini filtreleyerek işlemi yapalım
    const filteredData = allData.filter((item) => {
      // Bu flag, item'ın temporaryPassive arrayine taşınıp taşınmayacağını belirler
      let shouldMoveToTemporaryPassive = false;

      if (!item.closedDates || item.closedDates.length === 0) {
        return true; // Bu item, dataFromApi'de kalacak
      }
      if (item.status === '1') {
        item.closedDates.forEach((closedDate) => {
          const [day, month, year] = closedDate.startDate.split('.');
          const [endDay, endMonth, endYear] = closedDate.endDate.split('.');
          const [startHour, startMinute] = closedDate.startTime.split(':');
          const [endHour, endMinute] = closedDate.endTime.split(':');

          const startDate = new Date(
            year,
            month - 1,
            day,
            startHour,
            startMinute
          );
          const endDate = new Date(
            endYear,
            endMonth - 1,
            endDay,
            endHour,
            endMinute
          );

          // Eğer currentDate, closedDates aralığı içindeyse item'ı temporaryPassive arrayine taşıyacağız
          if (currentDate >= startDate && currentDate <= endDate) {
            shouldMoveToTemporaryPassive = true;
          }
        });
      }

      // Eğer item taşınacaksa temporaryPassive arrayine ekleyelim ve false döndürelim (filter için)
      if (shouldMoveToTemporaryPassive) {
        temporaryPassive.push({
          ...item,
          isTemporaryPassive: true, // isTemporaryPassive flag'i ekleniyor
        });
        return false; // Bu item, allData'dan çıkarılacak
      }

      return true; // Bu item, allData'da kalacak
    });

    let newOriginalData = filteredData.concat(temporaryPassive);

    setTemporaryPassivePoints(temporaryPassive);
    setOrderList(filteredData);
    setActivePoints(filteredData.filter((point) => point.status === '1'));
    setPassivePoints(filteredData.filter((point) => point.status === '0'));
    setOriginalData(newOriginalData);
  }

  function getPoints() {
    api
      .post('flow/get-points')
      .then((response) => {
        if (response.data.status) {
          getTemporaryPassiveList(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  function getMalls() {
    api
      .post('/malls/get-malls')
      .then((response) => {
        if (response.data.status) {
          setMalls(response.data.result);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  useEffect(() => {
    getUsers();
    getMalls();
    getPoints();
    getDirections();
  }, [customer]);

  return (
    <>
      <Helmet>
        <title> Noktalar | Easy Point Panel </title>
      </Helmet>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography className="ml-2" variant="h4" gutterBottom>
          Noktalar
        </Typography>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2} paddingX={1} alignItems="end">
          <AddEntityButton
            onClick={() => handleOpenAddEditPointModal()}
            label={'Nokta Ekle'}
            icon={'ri:map-pin-line'}
          />
          <ExportToExcelButton
            setShowPopUpError={setShowPopUpError}
            showPopUpError={showPopUpError}
            sx={{ marginLeft: '8px' }} onClick={() => exportToExcel()} />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent={isMobile ? 'space-between' : 'end'}
        mb={5}
        paddingX={2}
      >
        {isMobile ? (
          <FilterViewButton variant="contained" onClick={handleModal} />
        ) : null}
      </Stack>
      <Card>

        <CustomFilter
          modalType={'drawer'}
          filterModal={filterModal}
          handleCloseModal={handleCloseModal}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingY={2}
            paddingX={1}
          >
            <Stack
              mr={1}
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{ marginBottom: 2, flex: 1 }}
            >
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Nokta Adı"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  sx={{ mt: 2, marginRight: '8px', flex: 1 }}
                />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Şube Kodu"
                  variant="outlined"
                  value={branchCode}
                  onChange={(e) => setBranchCode(e.target.value)}
                  sx={{ mt: 2, marginRight: '8px', flex: 1 }}
                />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="İl"
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  sx={{ mt: 2, marginRight: '8px', flex: 1 }}
                />
              </FormControl>


              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="İlçe"
                  variant="outlined"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  sx={{ mt: 2, marginRight: '8px', flex: 1 }}
                />
              </FormControl>


            </Stack>
          </Stack>
          <Stack>
            {originalData.length > 0 && (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  paddingY={2}
                  paddingX={1}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontFamily: 'Inter',
                      fontSize: 14,
                      color: '#6B7280',
                      letterSpacing: 0.15,
                    }}
                  >
                    Noktalar:
                  </Typography>
                  <Button
                    onClick={() => getFilteredPointListByTag('isAmazon')}
                    sx={{
                      height: '26px',
                      backgroundColor: filterTags.includes('isAmazon')
                        ? '#DC423C'
                        : '#F3F4F6',
                      padding: '4px 12px 4px 10px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      border: filterTags.includes('isAmazon')
                        ? null
                        : '1px solid #D0D5DD',
                      color: filterTags.includes('isAmazon') ? 'white' : '#6B7280',
                      '&:hover': {
                        backgroundColor: filterTags.includes('isAmazon')
                          ? '#B53228'
                          : null,
                      },
                      fontSize: 14,
                      fontWeight: 500,
                      fontFamily: 'Inter',
                      marginLeft: '25px',
                    }}
                  >
                    Amz
                  </Button>
                  <div
                    style={{
                      width: '18px',
                      height: '1px',
                      borderTop: '1px solid #EAECF0',
                      transform: 'rotate(90deg)',
                    }}
                  ></div>
                  <Button
                    onClick={() => getFilteredPointListByTag('isHepsiburada')}
                    sx={{
                      height: '26px',
                      backgroundColor: filterTags.includes('isHepsiburada')
                        ? '#DC423C'
                        : '#FFF4ED',
                      padding: '4px 12px 4px 10px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      border: filterTags.includes('isHepsiburada')
                        ? null
                        : '1px solid #FFD6AE',
                      color: filterTags.includes('isHepsiburada')
                        ? 'white'
                        : '#FF9C66',
                      '&:hover': {
                        backgroundColor: filterTags.includes('isHepsiburada')
                          ? '#B53228'
                          : null,
                      },
                      fontSize: 14,
                      fontWeight: 500,
                      fontFamily: 'Inter',
                    }}
                  >
                    H.B
                  </Button>
                  <div
                    style={{
                      width: '18px',
                      height: '1px',
                      borderTop: '1px solid #EAECF0',
                      transform: 'rotate(90deg)',
                    }}
                  ></div>
                  <Button
                    onClick={() => getFilteredPointListByTag('isTrendyol')}
                    sx={{
                      height: '26px',
                      backgroundColor: filterTags.includes('isTrendyol')
                        ? '#DC423C'
                        : '#FFF4ED',
                      padding: '4px 12px 4px 10px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      border: filterTags.includes('isTrendyol')
                        ? null
                        : '1px solid #FFD6AE',
                      color: filterTags.includes('isTrendyol')
                        ? 'white'
                        : '#6B7280',
                      '&:hover': {
                        backgroundColor: filterTags.includes('isTrendyol')
                          ? '#B53228'
                          : null,
                      },
                      fontSize: 14,
                      fontWeight: 500,
                      fontFamily: 'Inter',
                    }}
                  >
                    Trendyol
                  </Button>
                </Stack>
                <StatusFilters
                  label={'Durum:'}
                  activeOnClick={() => {
                    setOrderList(activePoints),
                      setSelectedStatusFilter('1'),
                      getFilteredPointList('1');
                  }}
                  passiveOnClick={() => {
                    setOrderList(passivePoints),
                      setSelectedStatusFilter('0'),
                      getFilteredPointList('0');
                  }}
                  temporaryPassiveOnClick={() => {
                    setOrderList(temporaryPassivePoints),
                      setSelectedStatusFilter('3'),
                      getFilteredPointList('3');
                  }}
                  selectedButton={selectedStatusFilter}
                  setSelectedButton={setSelectedStatusFilter}
                />
              </>
            )}
          </Stack>
          <Divider sx={{ width: '100%', paddingTop: '10px' }} />

          <Stack
            mr={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            paddingY={2}
            paddingX={1}
          >
            <ClearButton onClick={() => clearFilters()} />
            <FilterButton onClick={() => getFilteredPointList()} />

          </Stack>

        </CustomFilter>

        <HorizontalDivider />



        <DynamicTable
          data={orderList}
          headers={TABLE_HEAD}
          initialOrder={'name'}
          initalOrderDirection={'desc'}
          isOrdered={true}
          isMobile={isMobile}
          handleModal={handleModal}
          handleCloseModal={handleCloseModal}
          rowOnClick={(row) => handleEdit(row)}
        />


        {orderList.length === 0 && (
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              padding: 20,
            }}
          >
            <Typography>Gösterilecek veri mevcut değil.</Typography>
          </div>
        )}
      </Card>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{selectedPoint?.title}</DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '200px',
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <img src={qr} alt="QR Code" style={{ width: '100%' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDownload}
            style={{ maxWidth: 500 }}
          >
            İndir
          </Button>
          <Button onClick={handleCloseDialog}>Kapat</Button>
        </DialogActions>
      </Dialog>

      {addEditPointOpen && (
        <AddEditPointModal
          open={addEditPointOpen}
          onClose={handleCloseAddEditPointModal}
          isEdit={isModalEdit}
          editData={editData}
          setEditData={setEditData}
          malls={malls}
          userList={userList}
        />
      )}
      <Dialog
        open={mobileFilterModal}
        onClose={() => setMobileFilterModal(false)}
      >
        <DialogTitle>Filtreler</DialogTitle>
        <DialogContent>
          {minWidth768 ? (
            ''
          ) : (
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              alignContent="center"
              gap="10px"
            >
              <TextField
                label="Nokta Adı"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                label="Şube Kodu"
                variant="outlined"
                value={branchCode}
                onChange={(e) => setBranchCode(e.target.value)}
              />

              <TextField
                label="İl"
                variant="outlined"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <TextField
                label="İlçe"
                variant="outlined"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              />

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClearButton onClick={() => clearFilters()} />
                <FilterButton onClick={() => getFilteredPointList()} />
              </div>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
