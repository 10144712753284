import React from 'react';
import Iconify from '../iconify';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const EditButton = ({ onClick, to, icon, tooltip = "Düzenle" }) => {
    return (
        <>
            {icon ? (
                <>
                    {to ? (
                        <Tooltip title={tooltip}>
                            <Link style={{ textDecoration: 'none', fontWeight: 800 }} to={to}>
                                <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon={icon} />
                            </Link>
                        </Tooltip>
                    ) : (
                        <Tooltip title={tooltip}>
                            <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon={icon} onClick={onClick} />
                        </Tooltip>
                    )}
                </>
            ) : (
                <>
                    {to ? (
                        <Tooltip title={tooltip}>
                            <Link style={{ textDecoration: 'none', fontWeight: 800 }} to={to}>
                                <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon="akar-icons:edit" />
                            </Link>
                        </Tooltip>
                    ) : (
                        <Tooltip title={tooltip}>
                            <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon="akar-icons:edit" onClick={onClick} />
                        </Tooltip>
                    )}
                </>
            )}
        </>
    );
};

export default EditButton;
