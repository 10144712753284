import { Button } from '@mui/material'
import React from 'react'
import Iconify from 'src/components/iconify/Iconify'

export default function OptionsButton({title, onClick}) {
    return (
        <Button
            onClick={onClick}
            sx={{
                width: '100%',
                backgroundColor: '#DC423C',
                color: 'white',
                marginTop: '21px',
                height: '54px',
                justifyContent: 'space-between',
                display: 'flex',
                gap: '12px',
                paddingLeft: '22px',
                paddingRight: '22px',
                '&:hover': {
                    backgroundColor: '#B53832'
                },
            }}
        >
            {title}  <Iconify icon={'zondicons:add-outline'} />
        </Button>
    )
}
