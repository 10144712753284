import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Typography,
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  Modal,
  Box,
  InputLabel,
  Autocomplete,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
// sections
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
// ----------------------------------------------------------------------
import { StatusList } from 'src/types/statusTypes';
import { CreatedUsersTypes } from 'src/types/createdUsersTypes';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import Iconify from 'src/components/iconify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLocalization } from 'src/context/LocalizationContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const all = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function AllActionsPage() {
  const { language } = useLocalization();
  const customer = useSelector((state) => state.customerReducer);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [allDatas, setAllDatas] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedCargoStatus, setSelectedCargoStatus] = useState([]);
  const [barcode, setBarcode] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const [point, setPoint] = useState('');
  const [epBranchCode, setEpBranchCode] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [mobileAll, setMobileAll] = useState(false);
  const [pointTitlesOptions, setPointTitlesOptions] = useState([]);

  const [selectedPoint, setSelectedPoint] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [selectedDeliveryTypes, setSelectedDeliveryTypes] = useState([]);
  const [apiTypes, setApiTypes] = useState('');
  const [showPopUpError, setShowPopUpError] = useState(false);
  const [secondFilter, setSecondFilter] = useState(false);

  const parsedUser = JSON.parse(localStorage.getItem('user'));

  const handleMobileAllOpen = () => setMobileAll(true);
  const handleMobileAllClose = () => setMobileAll(false);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const handleCopy = (branchCode) => {
    navigator.clipboard.writeText(branchCode)
  };

  const TABLE_HEAD = [
    { id: 'title', label: language.commons.column.title, alignRight: false },
    { id: 'barcode', label: language.commons.column.barcode, alignRight: false },
    { id: 'willTakenBy', label: language.commons.column.receiver, alignRight: false, format: (value) => parsedUser.type === "esnaf" ? maskName(value) : value },
    { id: 'deliverValue', label: language.commons.column.condition, alignRight: false, format: (value, row) => getStatusLabel(+row.status) },
    { id: 'pointTitle', label: language.commons.column.point, alignRight: false },
    {
      id: 'ep_branch_code',
      label: language.commons.column.branch_code,
      alignRight: false,
      format: (value, row) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '50px' }}>
          <span style={{ marginRight: '6px' }}>{row.branchCode}</span>
          {row.branchCode && row.branchCode !== "NA" &&
            <ContentCopyIcon
              style={{ cursor: 'pointer', color: '#98A2B3' }}
              onClick={() => handleCopy(row.branchCode)}
            />
          }

        </div>
      )
    },
    { id: 'shipmentFirm', label: language.commons.column.cargo_company, alignRight: false, format: (value, row) => row.shipmentFirm !== '' ? row.shipmentFirm : 'Nakliye' },
    { id: 'dropOff', label: language.commons.column.drop_off, alignRight: false, format: (value, row) => row.isDropOff === '0' ? 'Hayır' : 'Evet' },
    {
      id: 'createdBy', label: language.commons.column.creator, alignRight: false, format: (value, row) => row.apiUserType === 'handsfree' &&
        (row.additionalNote?.createdBy?.type === 'store'
          ? language.commons.column.store
          : language.commons.customer)
    },
    { id: 'createdDateTime', label: language.commons.column.created_date, alignRight: false, format: (value, row) => moment.unix(row.createdDateTime).format('DD/MM/YYYY') },
    { id: 'createdTime', label: language.commons.labels.createdDateTimeFormat, alignRight: false, format: (value, row) => moment.unix(row.createdDateTime).format('HH:mm') },
    {
      id: 'completedDateTime', label: language.commons.labels.completedDateTime, alignRight: false, format: (value, row) => (row.status === '1' ||
        row.status === '17' ||
        row.status === '21') &&
        moment
          .unix(row.completedDateTime)
          .format('DD/MM/YYYY')
    },
    {
      id: 'completedTime', label: language.commons.labels.completedDateTimeFormat, alignRight: false, format: (value, row) => (row.status === '1' ||
        row.status === '17' ||
        row.status === '21') &&
        moment
          .unix(row.completedDateTime)
          .format('HH:mm')
    },
    {
      id: 'action', label: '', alignRight: false, format: (value, row) =>
        <Tooltip title={language.commons.detail}>
          <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon="bx:detail" onClick={() => window.open(
            `/dashboard/post-details/${row.barcode}`,
            '_blank'
          )} />
        </Tooltip>

    },
  ];

  function maskName(name) {
    if (!name) return '';

    const parts = name.split(' ');

    const maskedParts = parts.map(part => {
      if (part.length <= 2) {
        return part;
      }
      const firstTwoLetters = part.substring(0, 2);
      const remaining = '*'.repeat(part.length - 2);
      return firstTwoLetters + remaining;
    });

    const maskedName = maskedParts.join(' ');

    return maskedName;
  }

  // Service
  const getUniquePointTitles = (data) => {
    const uniqueTitles = new Map();
    data.forEach((item) => {
      if (item.title && !uniqueTitles.has(item.title)) {
        uniqueTitles.set(item.title, { id: item.id, title: item.title });
      }
    });
    return Array.from(uniqueTitles.values());
  };

  function exportToExcel() {
    let temp1 = filteredOrders.map((x, index) => {
      let statusLabel = getStatusLabel(+x.status);
      let createdDateTime = moment.unix(x.createdDateTime).format('DD/MM/YYYY');
      let createdDateHour = moment.unix(x.createdDateTime).format('HH:mm');
      let completedDateTime = x.completedDateTime
        ? moment.unix(x.completedDateTime).format('DD/MM/YYYY')
        : '';
      let completedDateHour = x.completedDateTime
        ? moment.unix(x.completedDateTime).format('HH:mm')
        : '';

      let a = {
        id: index + 1,
        title: x.title,
        barcode: x.barcode,
        willTakenBy: x.willTakenBy,
        status: statusLabel,
        pointTitle: x.pointTitle,
        ep_branch_code: x.ep_branch_code,
        shipmentFirm: x.shipmentFirm,
        createdDateTime: createdDateTime,
        createdDateHour: createdDateHour,
        completedDateTime: completedDateTime,
        completedDateHour: completedDateHour,
      };
      return a;
    });

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          No: 'number',
          Başlık: 'string',
          Barkod: 'string',
          Alıcı: 'string',
          Durumu: 'string',
          Noktası: 'string',
          'Şube Kodu': 'string',
          'Kargo Firması': 'string',
          'Oluşturulma Tarihi': 'string',
          'Oluşturulma Saati': 'string',
          'Tamamlanma Tarihi': 'string',
          'Tamamlanma Saati': 'string',
        },
        sheetTitle: 'Gönderiler',
      })
      .then((response) => {
        console.log('excel: ', response.data);
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
              setShowPopUpError(true)
              throw new Error('Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.');
            }
          } catch (e) {
            setShowPopUpError(true)
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  function getCenterTitles(parsedUser) {
    const titles = [];

    for (const key in parsedUser.allowedPoints) {
      const centers = parsedUser.allowedPoints[key].centers;

      if (Array.isArray(centers)) {
        centers.forEach(center => {
          titles.push(center.title);
        });
      }

    }
    if (!titles.includes(parsedUser.firstResponsibleOffice.title)) {
      titles.push(parsedUser.firstResponsibleOffice.title);
    }

    return titles;
  }




  function getPoints() {
    api
      .post('flow/get-points')
      .then((response) => {
        if (response.data.status) {
          let pointTitles = getUniquePointTitles(response.data.result);
          if (!parsedUser.frontAuth.includes('manager')) {
            const titles = getCenterTitles(parsedUser);
            let filteredTitles = (pointTitles.filter(point => titles.includes(point.title)));
            setPointTitlesOptions(filteredTitles);
          }
          else {
            setPointTitlesOptions(pointTitles);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }
  function getFilteredOrderList() {
    console.log(parsedUser);
    setSecondFilter(true);
    // console.log(customer?.customer?.branchID, customer?.customer?.id);
    // console.log(selectedCargoStatus);
    api
      .post('posts/get', {
        // branchID: customer?.customer?.branchID || null,
        // mallID: customer?.customer?.id || null,
        limit: 5000,
        status:
          selectedCargoStatus && selectedCargoStatus?.length > 0
            ? selectedCargoStatus?.map((x) => x.id)
            : StatusList.filter((x) => x.id != 0).map((x) => x.id),
        startDate: startDate
          ? moment(startDate).format('DD-MM-YYYY')
          : startDate,
        endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : endDate,
        sentToEasyPoint: selectedPoint,
        apiType: apiTypes,
      })
      .then((response) => {
        if (response.data.status) {
          // let pointTitles = getUniquePointTitles(response.data.result)
          // setPointTitlesOptions(pointTitles)
          // console.log(response.data.result);
          setOrderList(response.data.result);
          setAllDatas(response.data.result);
          setFilteredData(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  useEffect(() => {
    getPoints();
  }, []);



  function clearFilters() {
    setStartDate(null);
    setEndDate(null);
    setSelectedCargoStatus([]);
    setSelectedDeliveryTypes([]);
    setBarcode('');
    setEpBranchCode('');
    // getFilteredOrderList();
    setFilteredData([]);
    setAllDatas([]);
    setOrderList([]);
    setPage(0);
    setSelectedPoint(null);
    setName('');
    setType('');
    setOrderNo('');
    setPoint('');
    setEpBranchCode('');
    setApiTypes('');
    setSecondFilter(false);
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCargoStatus(value);
  };


  const filterByBarcode = (text) => {
    const _barcode = text.toLowerCase();
    const filteredOrders = allDatas.filter((item) =>
      item.barcode.toLowerCase().includes(_barcode)
    );
    setBarcode(_barcode);
    setOrderList(filteredOrders);
    setFilteredData(filteredOrders);
  };

  const filterByBranchCode = (text) => {
    const _branchCode = text.toLowerCase();
    const filteredOrders = allDatas.filter((item) =>
      item.ep_branch_code.toLowerCase().includes(_branchCode)
    );
    setEpBranchCode(_branchCode);
    setOrderList(filteredOrders);
    setFilteredData(filteredOrders);
  };

  // const filterByOrderNo = (text) => {
  //   const _orderNo = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.orderNumber?.toLowerCase().includes(_orderNo)
  //   );
  //   setOrderNo(_orderNo);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const filterByName = (text) => {
  //   const _name = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.willTakenBy.toLowerCase().includes(_name)
  //   );
  //   setName(_name);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const filterByType = (text) => {
  //   const apiType = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.apiUserType.toLowerCase().includes(apiType)
  //   );
  //   setType(apiType);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const filterByPoint = (text) => {
  //   const _point = text.toLowerCase();
  //   const filteredOrders = allDatas.filter((item) =>
  //     item.pointTitle.toLowerCase().includes(_point)
  //   );
  //   setPoint(_point);
  //   setOrderList(filteredOrders);
  //   setFilteredData(filteredOrders);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setPage(0);
  //   setRowsPerPage(parseInt(event.target.value, 10));
  // };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList?.length) : 0;

  // const isNotFound = !filteredOrders?.length && !!filterName;


  const filteredOrders = applySortFilter(
    filteredData,
    getComparator(order, orderBy),
    filterName
  );


  const getStatusLabel = (statusValue) => {
    const statusObject = StatusList.find((status) => status.id === statusValue);
    return statusObject ? statusObject.label : '';
  };


  const getFilteredActionList = () => {
    let filteredActionList;
    if (barcode || name || type || orderNo || point || epBranchCode) {
      filteredActionList = allDatas.filter((item) => {
        const barcodeMatch = barcode
          ? item.barcode.toLowerCase().includes(barcode.toLowerCase())
          : true;
        const nameMatch = name
          ? item.willTakenBy.toLowerCase().includes(name.toLowerCase())
          : true;
        const typeMatch = type
          ? item.apiUserType.toLowerCase().includes(type.toLowerCase())
          : true;
        const orderNoMatch = orderNo
          ? item.orderNumber.toLowerCase().includes(orderNo.toLowerCase())
          : true;
        const pointMatch = point
          ? item.pointTitle.toLowerCase().includes(point.toLowerCase())
          : true;
        const epBranchCodeMatch = epBranchCode
          ? item.ep_branch_code
            .toLowerCase()
            .includes(epBranchCode.toLowerCase())
          : true;

        return (
          barcodeMatch &&
          nameMatch &&
          typeMatch &&
          orderNoMatch &&
          pointMatch &&
          epBranchCodeMatch
        );
      });
    } else {
      filteredActionList = allDatas;
    }

    setFilteredData(filteredActionList);
  };

  const clearFilterAndInputs = () => {
    setBarcode('');
    setName('');
    setType('');
    setOrderNo('');
    setPoint('');
    setEpBranchCode('');
    setFilteredData(allDatas);

  };

  return (
    <>
      <Helmet>
        <title> {language.all_action_page.titles.site_title} </title>
      </Helmet>

      <div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          paddingX={2}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '2%',
              width: '100%',
            }}
          >
            <Typography variant="h4" gutterBottom>
              {language.all_action_page.titles.title}
            </Typography>
          </div>
          <ExportToExcelButton
            setShowPopUpError={setShowPopUpError}
            showPopUpError={showPopUpError}
            sx={{ marginLeft: '8px' }}
            onClick={() => exportToExcel()}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
          paddingX={2}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Modal
          open={mobileAll}
          onClose={handleMobileAllClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box component="form" sx={all}>
            <Typography
              style={{ fontWeight: '600', fontSize: '18px', color: '#1F2A37' }}
              component="div"
            >
              {language.all_action_page.titles.filters}
            </Typography>
            <hr />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={language.commons.labels.start_date}
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={language.commons.labels.end_date}
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >

              <TextField
                label={language.commons.column.barcode}
                variant="outlined"
                autoComplete="off"
                value={barcode}
                onChange={(e) => filterByBarcode(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >

              <Stack style={{ width: '50%' }}>
                <FormControl>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedCargoStatus}
                    input={<OutlinedInput />}
                    onChange={handleChange}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>{language.commons.status}</em>;
                      }

                      return selected.map((x) => x?.label).join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {StatusList.map((x) => (
                      <MenuItem key={x.id} value={x}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginBottom: '3%',
              }}
            >
              <TextField
                style={{ width: '50%' }}
                label={language.commons.column.branch_code}
                variant="outlined"
                autoComplete="off"
                value={epBranchCode}
                onChange={(e) => filterByBranchCode(e.target.value)}
              />
            </div>
            <div
              style={{
                height: '20px',
                width: '1px',
                backgroundColor: '#EAECF0',
                margin: '0 15px',
                marginLeft: '-2px',
              }}
            ></div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton onClick={() => getFilteredOrderList()} />
            </div>
          </Box>
        </Modal>

        <Card>
          <Stack direction="row" paddingLeft={3} paddingRight={3} paddingTop={3} spacing={1} alignItems="center">
            <ErrorOutlineIcon sx={{ fontSize: 20 }} />
            <div>{language.all_action_page.titles.post_filter}</div>
          </Stack>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <Typography sx={{ marginLeft: 1, marginTop: 2 }}>
              {language.all_action_page.titles.post_list}
            </Typography>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={2}
              paddingX={1}
              spacing={2}
              width="100%"
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{ width: '100%' }}
              >
                <FormControl sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ flex: 1 }}
                      format="DD/MM/YYYY"
                      label={language.commons.labels.start_date}
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ flex: 1 }}
                      format="DD/MM/YYYY"
                      label={language.commons.labels.end_date}
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                sx={{ width: '100%' }}
              >
                <FormControl sx={{ flex: 1 }}>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedCargoStatus}
                    input={<OutlinedInput />}
                    onChange={handleChange}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>{language.commons.status}</em>;
                      }

                      return selected.map((x) => x?.label).join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {StatusList.map((x) => (
                      <MenuItem key={x.id} value={x}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id="demo-simple-select-label">
                    {language.commons.shipment_type}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={apiTypes}
                    label="Gönderi Tipi"
                    onChange={(e) => setApiTypes(e.target.value)}
                  >
                    <MenuItem value={'handsfree'}>{language.commons.brands.handsfree}</MenuItem>
                    <MenuItem value={'hepsiburada'}>{language.commons.brands.hepsiburada}</MenuItem>
                    <MenuItem value={'trendyol'}>{language.commons.brands.trendyol}</MenuItem>
                  </Select>
                </FormControl>
              </Stack>


              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <Autocomplete
                  noOptionsText={language.all_action_page.titles.no_match}
                  value={
                    pointTitlesOptions.find(
                      (option) => option.id === selectedPoint
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedPoint(newValue ? newValue.id : null);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={pointTitlesOptions}
                  getOptionLabel={(option) => option.title}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  sx={{ flex: 1 }}
                  renderInput={(params) => (
                    <TextField {...params} label={language.commons.labels.easypoint} />
                  )}
                />
              </FormControl>

            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              my={2}
              spacing={1}
              paddingX={1}
            >
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton
                label={language.commons.labels.list}
                onClick={() => getFilteredOrderList()}
              />

            </Stack>

            <HorizontalDivider />
            {secondFilter &&

              <>
                <Typography sx={{ marginLeft: 1, marginTop: 2 }}>
                  {language.all_action_page.titles.post_filter_empty}
                </Typography>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.column.barcode}
                      variant="outlined"
                      autoComplete="off"
                      value={barcode}
                      onChange={(e) => setBarcode(e.target.value)}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>

                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.labels.order_no}
                      variant="outlined"
                      autoComplete="off"
                      value={orderNo}
                      onChange={(e) => setOrderNo(e.target.value)}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>
                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.column.receiver}
                      variant="outlined"
                      autoComplete="off"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>

                  <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                    <TextField
                      label={language.commons.column.branch_code}
                      variant="outlined"
                      autoComplete="off"
                      value={epBranchCode}
                      onChange={(e) => setEpBranchCode(e.target.value)}
                      sx={{ my: 2, marginLeft: 1 }}
                    />
                  </FormControl>

                  <div
                    style={{
                      height: '20px',
                      width: '1px',
                      backgroundColor: '#EAECF0',
                      marginLeft: '15px',
                    }}
                  ></div>
                  <Stack direction="row" justifyContent="center">
                    <ClearButton
                      onClick={clearFilterAndInputs}
                    />
                    <FilterButton onClick={() => getFilteredActionList()} />
                  </Stack>

                </Stack>
              </>
            }

          </CustomFilter>


          <DynamicTable
            data={filteredOrders}
            headers={TABLE_HEAD}
            initialOrder={'name'}
            initalOrderDirection={'desc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}
            rowOnClick={(row) => window.open(
              `/dashboard/post-details/${row.barcode}`,
              '_blank'
            )}
          />
        </Card>
      </div>
    </>
  );
}
