// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` #webpack-dev-server-client-overlay {
     display: none !important;
 }

 #webpack-dev-server-client-overlay-div {
     display: none !important;
 }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"CAAC;KACI,wBAAwB;CAC5B;;CAEA;KACI,wBAAwB;CAC5B","sourcesContent":[" #webpack-dev-server-client-overlay {\n     display: none !important;\n }\n\n #webpack-dev-server-client-overlay-div {\n     display: none !important;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
