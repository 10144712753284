import React from 'react';
import { Button } from '@mui/material';
import { useLocalization } from 'src/context/LocalizationContext';

const ClearButton = ({ onClick,sx }) => {

    const { language } = useLocalization();
    return (
        <>
            <Button
                sx={{
                    marginRight: '8px',
                    color: '#6B7280',
                    borderColor: '#9CA3AF',
                    fontWeight: 500,
                    fontSize:14,
                    fontFamily:'Inter',
                    letterSpacing:0.4,
                    '&:hover': {
                        backgroundColor:'#ccc',
                        borderColor: '#9CA3AF',
                    },
                    width:94,
                    height:40,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap:'6px',
                    ...sx
                }}
                variant="outlined"
                onClick={onClick}
            >
               {language.components.ClearButton.clear}
            </Button>
        </>
    );
};

export default ClearButton;