import React from 'react';
import { Stack, Box, Typography, Divider } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useLocalization } from 'src/context/LocalizationContext';

const MultiChoiceChart = ({summary}) => {

    const { language } = useLocalization();
    return (
        <>
            <Stack flex={1}>
                <Typography sx={{ color: 'gray', fontSize: '13px' }}>{language.commons.surveys.questions}</Typography>

                {Object.entries(summary.multi_choice).map(([question, data], index) => (
                    <div key={`multi_choice-${index}`}>
                        <Stack flexDirection="row" alignItems="center" sx={{ gap: '5px' }}>
                            <Typography sx={{ margin: 0 }}><p>{index + 1}.</p></Typography>
                            <Typography dangerouslySetInnerHTML={{ __html: question }} />

                        </Stack>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        {/* <p>Toplam Cevap: {data.totalResponses}</p>
                        <ul>
                            {Object.entries(data.details).map(([option, count]) => (
                                <li key={option}>
                                    {option}: {count}
                                </li>
                            ))}
                        </ul> */}
                    </div>
                ))}
            </Stack>
            <Stack flex={1}>
                {(() => {
                    // Tüm şıkları belirlemek için tüm soruları tarar
                    const allOptions = Array.from(
                        new Set(
                            Object.values(summary.multi_choice)
                                .flatMap((data) => Object.keys(data.details))
                        )
                    ); 

                    const generateColors = (count) => {
                        const baseColors = [
                            '#6941C6', '#9E77ED', '#D1A8F7', '#A28AC6', '#6A4EC2', 
                            '#B59FFF', '#7A50C2', '#6F39B5', '#8D57D0', '#5D2DB7', 
                            '#7E5ACE', '#9B4FDD', '#6B3CBB', '#9054E4', '#6C39D7', 'gray' 
                        ];

                        const colors = [];
                        for (let i = 0; i < count; i++) {
                            colors.push(baseColors[i % baseColors.length]); // Renk paletini döngüye sok
                        }
                        return colors;
                    };

                    const colors = generateColors(allOptions.length); 

                    // Seriler, tüm şıklar için eksiksiz veri içerecek şekilde oluşturuluyor
                    const series = allOptions.map((option) => ({
                        name: option,
                        data: Object.entries(summary.multi_choice).map(([_, data]) =>
                            data.details[option] || 0 
                        ),
                    }));

                    const chartData = {
                        series,
                        options: {
                            chart: {
                                type: 'bar',
                                height: 400,
                                stacked: true,
                                toolbar: { show: false },
                            },
                            xaxis: {
                                categories: Object.entries(summary.multi_choice).map((_, index) => `${index + 1}. soru`), // "1. soru", "2. soru" gibi
                                title: {
                                    text: language.commons.surveys.answers2, 
                                    style: {
                                        fontSize: '14px',
                                        color: '#a7a6a6'

                                    },
                                },
                            },
                            yaxis: {
                                title: {
                                    text: language.commons.surveys.number_of_entrant, 
                                    style: {
                                        fontSize: '14px',
                                        color: '#a7a6a6',

                                    },
                                    offsetX: -8,
                                },
                                tickAmount: 1,
                                labels: {
                                    formatter: function (val) {
                                        return val.toFixed(0);
                                    }
                                },
                            },
                            tooltip: {
                                custom: ({ seriesIndex, dataPointIndex, w }) => {
                                    const question = Object.keys(summary.multi_choice)[dataPointIndex]; // Soru başlığı
                                    const details = summary.multi_choice[question].details; // Şıklar
                                    const tooltipContent = Object.entries(details).map(([option, count]) => {
                                        const color = colors[allOptions.indexOf(option) % colors.length];
                                        return `
                                <div style="display: flex; align-items: center; margin-top: 4px;">
                                    <span style="width: 12px; height: 12px; background-color: ${color}; display: inline-block; margin-right: 8px;"></span>
                                    <span>${option}: <strong>${count}</strong></span>
                                </div>
                            `;
                                    }).join("");

                                    return `
                            <div style="padding: 10px; border: 1px solid #ccc; background: #fff; border-radius: 4px;">
                                <strong>${question}</strong><br />
                                ${tooltipContent}
                            </div>
                        `;
                                },
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    columnWidth: '20%',

                                },
                            },
                            fill: {
                                colors,
                            },
                            legend: {
                                show: false, // Şıkların alt kısımda görünmesini engeller
                            },
                            title: {
                                text: language.commons.surveys.asnwer_of_questions,
                                align: 'center',
                            },
                        },
                    };

                    return (
                        <ReactApexChart
                            options={chartData.options}
                            series={chartData.series}
                            type="bar"
                            height={400}
                        />
                    );
                })()}
            </Stack>
        </>
    )
}

export default MultiChoiceChart