import React from 'react';
import { Button, IconButton, Tooltip, Modal, Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocalization } from 'src/context/LocalizationContext';

const ExportToExcelButton = ({ onClick, sx, showPopUpError, setShowPopUpError }) => {

    const { language } = useLocalization();

    const handleOpen = () => setShowPopUpError(true);
    const handleClose = () => setShowPopUpError(false);

    return (
        <>
            <Button
                sx={{
                    width: 142,
                    height: 40,
                    backgroundColor: '#088AB2',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    boxShadow: '0px 6px 12px rgba(8, 138, 178, 0.5)',
                    gap: '6px',
                    '&:hover': {
                        backgroundColor: '#067294',
                    },
                    ...sx

                }}
                variant="contained"
                onClick={onClick}
            >
                {language.components.ExportToExcelButton.export_excel}
            </Button>

            <Tooltip title={language.commons.labels.file_download}>
                <IconButton
                    size="small"
                    sx={{
                        color: 'gray',
                        marginLeft: '8px',
                        animation: 'pulseInfo 2s infinite',
                        '@keyframes pulseInfo': {
                            '0%': {
                                transform: 'scale(1)',
                                color: 'gray',
                            },
                            '50%': {
                                transform: 'scale(1.1)',
                                color: '#088AB2', // Açık mavi
                            },
                            '100%': {
                                transform: 'scale(1)',
                                color: 'gray',
                            },
                        },
                    }}
                    onClick={handleOpen}
                >
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>

            <Modal
                open={showPopUpError}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        borderRadius: '10px',
                        boxShadow: 24,
                        p: 4,
                        justifyContent: 'flex-end',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <h3 id="modal-title">
                        {language.commons.labels.warning_file_download}
                    </h3>
                    <img
                        src={require('../../assets/images/popUpError.png')}
                        alt="Pop-up Error"
                        style={{
                            display: 'block',
                            margin: '20px auto',
                            maxWidth: '100%',
                            height: 'auto',
                        }}
                    />
                    <p id="modal-description">
                        {language.commons.warnings.excel_browser}
                    </p>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        {language.commons.okay}
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default ExportToExcelButton;