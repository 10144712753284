import React from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useLocalization } from 'src/context/LocalizationContext';

const RatingChart = ({ summary }) => {
    const { language } = useLocalization();
    // Pie chart için veri hazırlama
    const generatePieChartData = () => {
        return Object.entries(summary).map(([question, data]) => ({
            name: question, // Sorunun başlığı
            y: data.totalResponses, // Sorudan gelen toplam cevap sayısı
        }));
    };

    const generateColors = (count) => {
        const baseColors = [
            '#6941C6', '#9E77ED', '#D1A8F7', '#A28AC6', '#6A4EC2', 
            '#B59FFF', '#7A50C2', '#6F39B5', '#8D57D0', '#5D2DB7', 
            '#7E5ACE', '#9B4FDD', '#6B3CBB', '#9054E4', '#6C39D7', 'gray', 
        ];
        return baseColors.slice(0, count);
    };

    const calculatePercentage = (totalResponses, questionResponses) => {
        return (questionResponses / totalResponses) * 100;
    };

    // Tooltip özelleştirme fonksiyonu
    const customTooltip = ({ seriesIndex, dataPointIndex, w }) => {
        const question = Object.keys(summary)[seriesIndex]; // Sorunun başlığı
        const data = summary[question].details; // Sorunun yanıt detayları
        const responses = Object.entries(data).map(([rating, count]) => (
            `<strong>Puan ${rating}:</strong> ${count} kişi<br/>`
        )).join('');

        return `
            <div>
                <strong>${question}</strong><br/>
                ${responses}
                <strong>Toplam ${language.commons.surveys.answer}:</strong> ${summary[question].totalResponses}
            </div>
        `;
    };

    const totalResponses = Object.values(summary).reduce((acc, data) => acc + data.totalResponses, 0);

    return (
        <>
            <Stack flex={2}>
                <Typography sx={{ color: 'gray', fontSize: '13px' }}>{language.commons.surveys.questions}</Typography>
                {Object.entries(summary).map(([question, data], index) => (
                    <div key={`rating-${index}`}>
                        <Stack flexDirection="row" alignItems="center" sx={{ gap: '5px' }}>
                            <Typography sx={{ margin: 0 }}><p>{index + 1}.</p></Typography>
                            <Typography dangerouslySetInnerHTML={{ __html: question }} />
                        </Stack>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                    </div>
                ))}
            </Stack>

            <Stack flex={1} flexDirection="row" justifyContent="space-between">
                <Stack>
                    <Typography sx={{ color: 'gray', fontSize: '13px' }}>{language.commons.surveys.answers}</Typography>
                    {Object.entries(summary).map(([question, data], index) => (
                        <div key={`totalResponses-${index}`} style={{ lineHeight: 1.5, minHeight: '56px', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                {data.totalResponses} {language.commons.surveys.answer}
                            </Typography>

                        </div>

                    ))}
                </Stack>

                <Stack>
                    <Typography sx={{ color: 'gray', fontSize: '13px' }}>{language.commons.surveys.percent}</Typography>
                    {Object.entries(summary).map(([question, data], index) => (
                        <div key={`percentage-${index}`} style={{ lineHeight: 1.5, minHeight: '56px', display: 'flex', alignItems: 'center' }}>
                            {/* Yüzde hesaplaması */}

                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                {calculatePercentage(totalResponses, data.totalResponses).toFixed(2)}%
                            </Typography>
                        </div>
                    ))}
                </Stack>
            </Stack>

            <Stack flex={1}>
                <Typography sx={{ color: 'gray', fontSize: '13px' }}>{language.commons.surveys.indication}</Typography>
                {/* Pie chart */}
                <div>
                    <ReactApexChart
                        options={{
                            chart: {

                                toolbar: { show: false },
                                animations: {
                                    enabled: true,
                                    easing: 'easeinout',
                                    speed: 800,
                                    animateGradually: {
                                        enabled: true,
                                        delay: 150,
                                    },
                                    dynamicAnimation: {
                                        enabled: true,
                                        speed: 350,
                                    },
                                },
                            },
                            labels: Object.keys(summary), // Soruların başlıkları
                            legend: {
                                position: 'bottom',
                            },
                            title: {
                                text: '',
                                align: 'center',
                            },
                            tooltip: {
                                custom: customTooltip, // Tooltip özelleştirme
                            },
                            colors: generateColors(Object.keys(summary).length), 
                            dataLabels: {
                                enabled: true,
                                formatter: (val, opts) => {
                                    return `${val.toFixed(2)}%`;
                                },
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        size: '50%', // Donut şeklinde pie chart yapma
                                    },
                                },
                            },
                        }}
                        series={generatePieChartData().map(item => item.y)} // Toplam cevap sayıları
                        type="donut"
                        height={350}
                    />
                </div>
            </Stack>
        </>
    );
};

export default RatingChart;
