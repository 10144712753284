import { Button, FormControl, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
const FormsStep1 = ({ handleOpenCloseConfirmation, setActiveStep, activeStep, setFormStep1, formStep1, isFormEdit, editData }) => {

    //console.log("isEdit",isFormEdit);
    // console.log("editData",editData);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormStep1({
            ...formStep1,
            [name]: value,
        });
    };

    const disabledSaveButton = () => {
        if (formStep1.surveyTitle && formStep1.surveyCategories && formStep1.surveyDesc) return false
        else return true
    }

    useEffect(() => {
        if (isFormEdit) {
            setFormStep1({
                surveyTitle: editData.title,
                surveyCategories: editData.category,
                surveyDesc: editData.description
            })
        }else {
            setFormStep1({
                surveyTitle: '',
                surveyCategories: '',
                surveyDesc: ''
            });
        }
    }, []);
    return (
        <>
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography id="transition-modal-title" variant="h3" component="h3">
                    Anket Oluştur
                </Typography>
                <Button
                    onClick={handleOpenCloseConfirmation}
                    sx={{ color: 'black', position: 'absolute', top: '5px', right: 0 }}
                ><CloseIcon />
                </Button>
            </Stack>
            <Stack spacing={2} flexDirection='column'>
                <FormControl sx={{ flex: 1, minWidth: '100%', padding: '4px', gap: '8px' }} >
                    <Typography id="transition-modal-prag" variant="h6" component="h6">
                        Anket Oluştur
                    </Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Anket Başlığı Girin"
                        value={formStep1.surveyTitle}
                        name="surveyTitle"
                        onChange={handleInputChange}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%', padding: '4px', gap: '8px' }} >
                    <Typography id="transition-modal-prag1" variant="h6" component="h6">
                        Anket Kategorisi - Müşteri Tipi
                    </Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Anket kategorisi [ML-EP]"
                        name='surveyCategories'
                        value={formStep1.surveyCategories}
                        onChange={handleInputChange}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%', padding: '4px', gap: '8px' }} >
                    <Typography id="transition-modal-prag2" variant="h6" component="h6">
                        Anket Açıklaması
                    </Typography>
                    <TextField
                        label="Anket Açıklaması..."
                        multiline
                        minRows={3}
                        maxRows={5}
                        variant="outlined"
                        fullWidth
                        name='surveyDesc'
                        value={formStep1.surveyDesc}
                        onChange={handleInputChange}
                    />
                </FormControl>
                <Button disabled={disabledSaveButton()} onClick={() => setActiveStep(activeStep + 1)} sx={{ height: '55px' }} variant="contained" color="primary">
                    Sonraki Adım
                </Button>
            </Stack>
        </>
    )
}

export default FormsStep1
