import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Typography } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { tokenService } from 'src/services';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const generateCombinedPermissions = (type, frontAuthArray) => {
    if (frontAuthArray === false) {
      return `${type}:${false}`;
    } else {
      return frontAuthArray.map((frontAuth) => `${type}:${frontAuth}`);
    }
  };

  const user = tokenService.getUser();
  const type = user?.type || '';
  const frontAuthArray = user?.frontAuth || false;
  const combinedPermissions = generateCombinedPermissions(type, frontAuthArray);

  let lastCategory = '';

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item, index) => {
          const currentCategory = item.category.toLocaleUpperCase() || '';
          const isVisible =
            combinedPermissions === 'superadmin:false' ||
            combinedPermissions === 'superadmin:true' ||
            item.permissions.some((permission) =>
              combinedPermissions.includes(permission)
            );

          if (!isVisible) {
            return null;
          }

          if (currentCategory !== lastCategory) {
            lastCategory = currentCategory;
            return (
              <div key={index}>
                <Typography
                  sx={{
                    color: '#B41E1E',
                    fontSize: "11px",
                    lineHeight:'13.31px',
                    fontWeight: '700',
                    marginBlock: '5px',
                    fontFamily: 'Inter, sans-serif',
                    margin:'5px',
                    marginLeft: '16px',

                  }}
                >
                  {currentCategory}
                </Typography>
                <NavItem item={item} />
              </div>
            );
          }

          return <NavItem key={index} item={item} />;
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const currentPath = window.location.pathname;

  const isActive = currentPath === path || currentPath.startsWith(path);

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: '#ffffff',
        '&.active': {
          color: '#DC423C',
          bgcolor: isActive ? '#ffffff' : 'inherit',
          fontWeight: isActive ? 'fontWeightBold' : 'inherit',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} sx={{ fontFamily: 'Inter, sans-serif', fontSize: 14, fontWeight: 500 }} />
      {info && info}
    </StyledNavItem>
  );
}

