import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Stack, Grid, Card, CardContent } from '@mui/material';
import { useLocalization } from 'src/context/LocalizationContext';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ShortAnswerChart = ({ summary }) => {

    const { language } = useLocalization();
    return (
        <Stack flex={0.9}>
            <Typography sx={{ color: 'gray', fontSize: '13px' }}>{language.commons.surveys.questions}</Typography>
            {Object.entries(summary.short_answer).map(([question, data], index) => (
                <Accordion key={`short_answer-${index}`}>
                    {/* Accordion başlığı (soru) */}
                    <AccordionSummary
                        className='special-short-answer'
                        aria-controls={`panel-${index}-content`}
                        id={`panel-${index}-header`}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {/* Sol tarafta soru başlığı */}
                        <Typography dangerouslySetInnerHTML={{ __html: question }} />

                        {/* Sağ tarafta "Cevapları Görüntüle" metni */}
                        <Box sx={{
                            ml: 'auto', color: 'gray', fontSize: '14px', maxWidth: '150px', '@media (max-width:600px)': {
                                maxWidth: '50px',  // Mobil için maxWidth
                            },
                        }}>
                            {language.commons.surveys.show_result}
                        </Box>
                    </AccordionSummary>

                    {/* Accordion içeriği (cevap detayları) */}
                    <AccordionDetails>
                        <Typography variant="body2" sx={{ marginBottom: 2 }}>
                           {language.commons.surveys.total_response}: {data.totalResponses}
                        </Typography>

                        {/* Grid ile her bir cevabı kutular içinde göstermek */}
                        <Grid container spacing={2}>
                            {Object.entries(data.details).map(([response, count]) => (
                                <Grid item xs={12} sm={6} md={4} key={response}>
                                    <Card sx={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: 3 }}>
                                        <CardContent>
                                            <Typography variant="h6" component="div">
                                                {response}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {language.commons.surveys.number_of_answers}: {count}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Stack>
    );
};

export default ShortAnswerChart;
