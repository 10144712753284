import React from 'react';
import { Box } from '@mui/material';

const HorizontalDivider = () => {

    return (
        <>
            <Box
                borderBottom={1}
                borderColor="#F3F4F6"
                width="100%"
            />
        </>
    );
};

export default HorizontalDivider;