// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes dragAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05); 
    }
    100% {
        transform: scale(1);
    }
}

.dragging {
    animation: dragAnimation 0.2s ease-in-out; 
}`, "",{"version":3,"sources":["webpack://./src/pages/createForm.css"],"names":[],"mappings":"AAAA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":["@keyframes dragAnimation {\n    0% {\n        transform: scale(1);\n    }\n    50% {\n        transform: scale(1.05); \n    }\n    100% {\n        transform: scale(1);\n    }\n}\n\n.dragging {\n    animation: dragAnimation 0.2s ease-in-out; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
