import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import api from 'src/services/BaseService';
import { tokenService } from 'src/services';
import { toast } from 'react-toastify';
import { useLocalization } from 'src/context/LocalizationContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

function CheckDeliveryRoom({ checkroomID }) {

  
  const { language } = useLocalization();

  const [openUserModal, setOpenUserModal] = React.useState(false);
  const handleOpenUserModal = () => setOpenUserModal(true);
  const handleCloseUserModal = () => setOpenUserModal(false);
  const [otp, setOtp] = React.useState('');

  const handleDeliveryRoom = () => {
    console.log('Service', tokenService.getUser());
    if (checkroomID?.length > 0) {
      api
        .post('flow/checkroom-deliver', {
          checkroomID: checkroomID,
          otp: otp,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            toast.success('İşleminiz başarıyla gerçekleşti.');
            window.location.reload();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('İşleminiz oluşturulurken bir hata oluştu.');
        });
    }
  };

  const handleResendOTP = () => {
    if (checkroomID?.length > 0) {
      api
        .post('flow/checkroom-send-otp', {
          checkroomID: checkroomID,
        })
        .then((response) => {
          console.log(response.data);
          if(response.data.status){
            toast.success('Yeniden şifre gönderildi.');
          }else{
            toast.error('Şifre yeniden gönderilirken bir hata oluştu.');
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('Şifre göndermede hata oluştu.');
        });
    }
  };
  
  return (
    <div>
      <Button onClick={handleOpenUserModal}>
        {language.commons.labels.deliver}
      </Button>
      <Modal
        open={openUserModal}
        onClose={handleCloseUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" align="center" gutterBottom>
            {language.components.CheckDeliveryRoom.deliver_code}
          </Typography>
          <TextField
            id="outlined-basic"
            label={language.components.CheckDeliveryRoom.deliver_customer}
            fullWidth
            name="otp"
            variant="outlined"
            style={{ marginBottom: '1rem' }}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button style={{ fontSize: '12px' }} variant="outlined" onClick={handleResendOTP}>
              {language.components.CheckDeliveryRoom.again_password}
            </Button>
            <Button style={{ fontSize: '12px' }} variant="outlined" onClick={handleDeliveryRoom}>
              {language.commons.labels.deliver}
            </Button>
            <Button style={{ fontSize: '12px' }} variant="outlined" onClick={handleCloseUserModal}>
              {language.commons.close}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CheckDeliveryRoom;
