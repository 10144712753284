import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import '../utils/cloakroom.css';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Services from 'src/services/Services';
import { toast } from 'react-toastify';
import { tokenService } from 'src/services';
import { CircularProgress, TextField, TextareaAutosize, useMediaQuery, FormControl, Stack } from '@mui/material';
import moment from 'moment';
import api from 'src/services/BaseService';
import CheckDeliveryRoom from './CheckDeliveryRoom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function EnhancedTable() {

  const { language } = useLocalization();
  const mallName = tokenService.getUser().firstResponsibleOffice.title;
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCreateButtonActive, setCreateButtonActive] = useState(false);
  const [customerID, setCustomerID] = useState('');


  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState('1');
  const [mobile, setMobile] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const TABLE_HEAD = [
    { id: 'sube', label: language.commons.column.chestroom, alignRight: false, format: (value, row) => mallName },
    { id: 'name', label: language.commons.labels.name, alignRight: false },
    { id: 'surname', label: language.commons.labels.lastname, alignRight: false, format: (value, row) => row.lastname },
    { id: 'checkroomNo', label: language.commons.column.chest_no, alignRight: false, format: (value, row) => row.checkroomID },
    { id: 'inventoryCount', label: language.commons.column.p_quantity, alignRight: false },
    { id: 'olusturmatarihi', label: language.commons.column.created_date, alignRight: false, format: (value, row) => moment.unix(row.createdDateTime).format('DD/MM/YYYY HH:mm') },
    {
      id: 'status', label: language.commons.column.condition, alignRight: false, format: (value, row) =>

        row.status === '1' ? (
          <Typography className="delivered">
            {language.commons.statusTypes.finish}
          </Typography>
        ) : row.status === '0' ? (
          <Typography className="delivered2">
            {language.commons.statusTypes.in_process}
          </Typography>
        ) : (
          <Typography className="delivered3">
            {language.commons.statusTypes.cancel}
          </Typography>
        )
    },
    {
      id: 'settings', label: language.commons.column.customer_note, alignRight: false, format: (value, row) =>
        <>
          <CheckDeliveryRoom
            checkroomID={row.checkroomID}
          />
          <Button
            onClick={() => {
              handleEditOpen(row);
            }}
          >
            {language.commons.edit}
          </Button>
        </>
    },
  ];


  const [filters, setFilters] = useState({
    searchName: '',
    searchSurname: '',
    searchCheckroomTitle: '',
    searchCheckroomNo: '',
  });

  const [user, setUser] = useState({
    name: '',
    lastname: '',
    phone: '',
    email: '',
  });

  const [data, setData] = useState({
    checkroomNo: '',
    inventoryCount: '',
    additionalNote: '',
  });

  const [openEdit, setOpenEdit] = useState(false);
  const [editedData, setEditedData] = useState({
    checkroomID: '',
    inventoryCount: '',
    additionalNote: '',
  });

  const [searchedValues, setSearchedValues] = useState({
    searchName: '',
    searchSurname: '',
    searchCheckroomTitle: '',
    searchCheckroomNo: '',
  });

  const handleNameChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, searchName: value }));
  };

  const handleSurnameChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, searchSurname: value }));
  };

  const handleCheckroomTitleChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, searchCheckroomTitle: value }));
  };

  const handleCheckroomNoChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, searchCheckroomNo: value }));
  };

  const handleFilterButtonClick = () => {
    setSearchedValues({ ...filters });
    getUserList(selectedStatus);
    // setUserList();
  };

  const handleChange = (event, object) => {
    const { name, value } = event.target;
    if (object === 'user') {
      setUser((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCreateCustomer = () => {
    api
      .post('flow/customers-register', {
        name: user.name,
        lastname: user.lastname,
        // email: user.email,
        phone: user.phone,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status) {
          toast.success('İşleminiz başarıyla gerçekleşti.');
          // window.location.reload();
        } else {
          toast.error('İşleminiz oluşturulurken hata oluştu.');
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('İşleminiz oluşturulurken bir hata oluştu.');
      });
  };

  const handleSearch = () => {
    if (user?.phone?.length > 2) {
      api
        .post('flow/customers-check', {
          type: 'phone',
          value: user.phone,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            setIsDisabled(true);
            setCustomerID(response.data.result.customer_id);
            setUser({
              name: response.data.result.name,
              lastname: response.data.result.lastname,
              email: response.data.result.email,
              phone: response.data.result.phone,
            });
          } else {
            setIsDisabled(false);
            setCreateButtonActive(true);
            setShowUserInfo(true);
            setUser({
              name: '',
              lastname: '',
              email: '',
              // phone: '',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setShowUserInfo(true);
    } else {
      toast.error('Telefon No Giriniz.');
    }
  };

  const handleCreateCheckRoom = () => {
    console.log('Service', tokenService.getUser());
    if (customerID?.length > 0) {
      api
        .post('flow/checkroom-create', {
          customerID: customerID,
          pointID: tokenService.getUser().firstResponsibleOffice.id,
          checkroomNo: data.checkroomNo,
          inventoryCount: +data.inventoryCount,
          additionalNote: data.additionalNote,
        })
        .then((response) => {
          console.log('createResponse: ', response.data);
          if (response.data.status) {
            toast.success('İşleminiz başarıyla gerçekleşti.');
            window.location.reload();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('İşleminiz oluşturulurken bir hata oluştu.');
        });
    } else {
      api
        .post('flow/customers-register', {
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          phone: user.phone,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            api
              .post('flow/checkroom-create', {
                customerID: response.data.result.customerID,
                pointID: tokenService.getUser().firstResponsibleOffice.id,
                checkroomNo: data.checkroomNo,
                inventoryCount: +data.inventoryCount,
                additionalNote: data.additionalNote,
              })
              .then((res) => {
                console.log(res.data);
                if (res.data.status) {
                  toast.success('İşleminiz başarıyla gerçekleşti.');
                  window.location.reload();
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
                toast.error('İşleminiz gerçekleşmedi.');
              });
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('İşleminiz gerçekleşirken bir hata oluştu.');
        });
    }
  };

  function getUserList(status) {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    Services.GetCheckRooms(parsedUser.token, status, startDate ? moment(startDate).format('YYYY-MM-DD') : startDate, endDate ? moment(endDate).format('YYYY-MM-DD') : endDate)
      .then((response) => {
        // console.log(response.data, "AAA");
        if (response.data.result) {
          const allUsers = response.data.result;
          setUserList(allUsers);
          // console.log('Veri', allUsers);
        } else {
          setUserList([]);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message)
        setUserList([]);
      })
      .finally(() => setLoading(false));
  }



  const handleEditOpen = (row) => {
    setEditedData({
      checkroomID: row.checkroomID,
      inventoryCount: row.inventoryCount,
      additionalNote: row.additionalNote,
    });
    setOpenEdit(true);
  };

  const handleEditClose = () => setOpenEdit(false);

  const handleSaveEditedData = () => {
    api
      .post(`flow/checkroom-update`, {
        checkroomID: editedData.checkroomID,
        inventoryCount: +editedData.inventoryCount,
        additionalNote: editedData.additionalNote,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status) {
          toast.success('Düzenleme işlemi başarıyla gerçekleşti.');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error('Düzenleme işleminde bir hata oluştu.');
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Düzenleme işlemi sırasında bir hata oluştu.');
      });
  };


  const handleClearRoom = () => {
    setFilters({
      searchName: '',
      searchSurname: '',
      searchCheckroomTitle: '',
      searchCheckroomNo: ''
    });
    setSearchedValues({
      searchName: '',
      searchSurname: '',
      searchCheckroomTitle: '',
      searchCheckroomNo: ''
    });
    setStartDate(null);
    setEndDate(null);
    //window.location.reload();
  };

  const filteredUserList = (userList) => {
    return userList.filter((user) => {
      return (
        user?.name?.toLowerCase().includes(searchedValues.searchName?.toLowerCase()) &&
        user?.lastname?.toLowerCase().includes(searchedValues.searchSurname?.toLowerCase()) &&
        mallName?.toLowerCase().includes(searchedValues.searchCheckroomTitle?.toLowerCase()) &&
        user?.checkroomNo?.toLowerCase().includes(searchedValues.searchCheckroomNo?.toLowerCase())
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    getUserList('1');
  }, []);



  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack paddingX={1} direction="row" justifyContent="space-between"

          >
            <Typography
              style={{
                fontSize: '22px',
                fontWeight: '600',
                lineHeight: '33px',
                color: '#1F2A37',
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {language.check_room.titles.site_title}
            </Typography>
            <div>
              <AddEntityButton
                onClick={() => setOpen(true)}
                label={language.commons.labels.add_record}
                icon={'iconoir:add-user'}
              />
            </div>

          </Stack>
          <Stack
            direction="row"
            justifyContent={isMobile ? 'space-between' : 'end'}
            mb={2}
            paddingX={2}
            paddingY={2}
          >
            {isMobile ? (
              <FilterViewButton variant="contained" onClick={handleModal} />
            ) : null}
          </Stack>

          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} component="form" noValidate autoComplete="off">
              <div
                style={{
                  fontWeight: 600,
                  color: '#e74c3c',
                  marginBottom: '1rem',
                }}
              >
                {language.check_room.titles.new_user}
              </div>

              <div
                style={{
                  display: showUserInfo ? 'flex' : 'none',
                  gap: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  name="name"
                  id="outlined--flexible"
                  label={language.commons.labels.name}
                  placeholder={language.commons.labels.name}
                  required
                  disabled={isDisabled}
                  value={user.name}
                  onChange={(e) => handleChange(e, 'user')}
                />
                <TextField
                  name="lastname"
                  id="outlined-textarea"
                  label={language.commons.labels.lastname}
                  placeholder={language.commons.labels.lastname}
                  value={user.lastname}
                  onChange={(e) => handleChange(e, 'user')}
                  required
                  disabled={isDisabled}
                />
              </div>
              <div
                style={{
                  display: showUserInfo ? 'flex' : 'none',
                  gap: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  style={{ width: '100%' }}
                  name="email"
                  id="outlined--flexible"
                  label={language.commons.labels.e_mail}
                  placeholder={language.commons.labels.e_mail}
                  value={user.email}
                  onChange={(e) => handleChange(e, 'user')}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  marginBottom: '1rem',
                  width: '100%',
                }}
              >
                <TextField
                  name="phone"
                  style={{ width: '100%' }}
                  id="outlined--flexible"
                  label={language.commons.labels.phone}
                  placeholder="5XXX"
                  value={user.phone}
                  onChange={(e) => handleChange(e, 'user')}
                  required
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  marginBottom: '1rem',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '1rem',
                }}
              >
                <Button variant="outlined" onClick={handleSearch}>
                  {language.commons.labels.query}
                </Button>
                <Button
                  variant="outlined"
                  disabled={!isCreateButtonActive}
                  onClick={handleCreateCustomer}
                >
                  {language.check_room.titles.new_user}
                </Button>
              </div>

              <div
                style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}
              >
                <TextField
                  name="checkroomNo"
                  id="outlined--flexible"
                  label={language.commons.labels.chest_no}
                  placeholder={language.commons.labels.chest_no}
                  value={data.checkroomNo}
                  onChange={handleChange}
                  required
                />
                <TextField
                  name="inventoryCount"
                  id="outlined-textarea"
                  label={language.commons.labels.piece_quantity}
                  placeholder={language.commons.labels.piece_quantity}
                  value={data.inventoryCount}
                  onChange={handleChange}
                  required
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  marginBottom: '1rem',
                  width: '100%',
                }}
              >
                <TextareaAutosize
                  color="danger"
                  name="additionalNote"
                  placeholder={language.commons.labels.additional_note}
                  style={{ width: '100%', borderRadius: '5px' }}
                  value={data.additionalNote}
                  onChange={handleChange}
                  minRows={5}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCreateCheckRoom}
                  disabled={
                    !user.name ||
                    !user.lastname ||
                    !user.phone ||
                    !data.checkroomNo ||
                    !data.inventoryCount
                  }
                >
                  {language.check_room.titles.create_register}
                </Button>
              </div>
            </Box>
          </Modal>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2} width="100%">


                <FormControl sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label={language.commons.labels.start_date}
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label={language.commons.labels.end_date}
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </LocalizationProvider>
                </FormControl>

              </Stack>

              <Stack direction="column" spacing={2}

              >
                <TextField
                  id="outlined-basic"
                  label={language.commons.labels.name}
                  variant="outlined"
                  value={filters.searchName}
                  onChange={handleNameChange}
                />

                <TextField
                  id="outlined-basic"
                  label={language.commons.labels.lastname}
                  variant="outlined"
                  value={filters.searchSurname}
                  onChange={handleSurnameChange}
                />

                <TextField
                  id="outlined-basic"
                  label={language.commons.column.chestroom}
                  variant="outlined"
                  value={filters.searchCheckroomTitle}
                  onChange={handleCheckroomTitleChange}
                />

                <TextField
                  id="outlined-basic"
                  label={language.commons.labels.chest_no}
                  variant="outlined"
                  value={filters.searchCheckroomNo}
                  onChange={handleCheckroomNoChange}
                />
              </Stack>

            </Stack>
            <Stack paddingY={2}>

              <Toolbar
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: '1rem',
                  flexWrap: isMobile && 'wrap'
                }}
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <div
                  style={{
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#6B7280',
                  }}
                >
                  {language.commons.labels.deliver_status}:
                </div>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    getUserList('0')
                    setSelectedStatus('0')
                  }}
                  className="delivered"
                  id="tableTitle"
                  component="div"
                >
                  <img
                    width="10"
                    height="10"
                    src="https://img.icons8.com/ios-glyphs/30/40C057/full-stop--v2.png"
                    alt="full-stop--v2"
                  />
                  {language.commons.statusTypes.finish}
                </Typography>
                <svg
                  width="1"
                  height="18"
                  viewBox="0 0 1 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499999"
                    y2="18"
                    stroke="#EAECF0"
                  />
                </svg>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    getUserList('1')
                    setSelectedStatus('1')
                  }}
                  className="delivered2"
                  id="tableTitle"
                  component="div"
                >
                  <img
                    width="10"
                    height="10"
                    src="https://img.icons8.com/material-rounded/24/7950F2/full-stop.png"
                    alt="full-stop"
                  />
                  {language.commons.statusTypes.in_process}
                </Typography>
                <svg
                  width="1"
                  height="18"
                  viewBox="0 0 1 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499999"
                    y2="18"
                    stroke="#EAECF0"
                  />
                </svg>
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    getUserList('3')
                    setSelectedStatus('3')
                  }}
                  className="delivered3"
                  id="tableTitle"
                  component="div"
                >
                  <img
                    width="10"
                    height="10"
                    src="https://img.icons8.com/material-rounded/24/FA5252/full-stop.png"
                    alt="full-stop"
                  />
                  {language.commons.statusTypes.cancel}
                </Typography>
              </Toolbar>

              <Stack direction="row" justifyContent="center" alignItems="center" paddingY={3}>
                <ClearButton onClick={handleClearRoom} />

                <FilterButton onClick={handleFilterButtonClick} />
              </Stack>
            </Stack>
          </CustomFilter>


          <Box className="Box" sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>


              <Modal
                open={openEdit}
                onClose={handleEditClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography variant="h5" align="center">
                    {language.check_room.titles.chest_edit}
                  </Typography>
                  <TextField
                    fullWidth
                    name="inventoryCount"
                    label={language.commons.labels.piece_quantity}
                    variant="outlined"
                    style={{ marginBottom: '1rem' }}
                    value={editedData.inventoryCount}
                    onChange={(e) =>
                      setEditedData({
                        ...editedData,
                        inventoryCount: e.target.value,
                      })
                    }
                  />
                  <TextareaAutosize
                    fullWidth
                    minRows={5}
                    placeholder={language.commons.labels.additional_note}
                    style={{
                      width: '100%',
                      marginBottom: '1rem',
                    }}
                    name="additionalNote"
                    value={editedData.additionalNote}
                    onChange={(e) =>
                      setEditedData({
                        ...editedData,
                        additionalNote: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                    }}
                  >
                    <Button variant="outlined" onClick={handleEditClose}>
                      {language.commons.close}
                    </Button>
                    <Button variant="outlined" onClick={handleSaveEditedData}>
                    {language.commons.save}
                    </Button>
                  </div>
                </Box>
              </Modal>


              <DynamicTable
                data={filteredUserList(userList)}
                headers={TABLE_HEAD}
                initialOrder={'name'}
                initalOrderDirection={'desc'}
                isOrdered={true}
                isMobile={isMobile}
                handleModal={handleModal}
                handleCloseModal={handleCloseModal}
              />
            </Paper>
          </Box>
        </>
      )}
    </>
  );
}
