import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import api from 'src/services/BaseService';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLocalization } from 'src/context/LocalizationContext';


const AddEditAvmModal = ({
  open,
  onClose,
  isEdit,
  editData,
  mallList,
  isBackdropOpen,
}) => {

  const { language } = useLocalization();
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  const [formData, setFormData] = useState({
    avmID: '',
    mallID: '',
    avmTitle: '',
    createAvmTitle: '',
    city: '',
    county: '',
    transportationTool: '',
    transportationPrice: '',
    transportationCurrency: '',
    hasMuhaberat: false,
    isParcelCargo: false,
    isSmallTool: false,
    isBigTool: false,
    isSuspendTool: false,
    isPallet: false,

    parcelCargoPrice: '',
    smallToolPrice: '',
    bigToolPrice: '',
    suspendToolPrice: '',
    palletPrice: '',

    parcelCargoCurrency: '',
    smallToolCurrency: '',
    bigToolCurrency: '',
    suspendToolCurrency: '',
    palletCurrency: '',

    isPrimary: false,
  });

  const cityList = [
    { id: 1, name: language.commons.cities.adana },
    { id: 2, name: language.commons.cities.adıyaman },
    { id: 3, name: language.commons.cities.afyonkarahisar },
    { id: 4, name: language.commons.cities.ağrı },
    { id: 5, name: language.commons.cities.amasya },
    { id: 6, name: language.commons.cities.ankara },
    { id: 7, name: language.commons.cities.antalya },
    { id: 8, name: language.commons.cities.artvin },
    { id: 9, name: language.commons.cities.aydın },
    { id: 10, name: language.commons.cities.balıkesir },
    { id: 11, name: language.commons.cities.bilecik },
    { id: 12, name: language.commons.cities.bingöl },
    { id: 13, name: language.commons.cities.bitlis },
    { id: 14, name: language.commons.cities.bolu },
    { id: 15, name: language.commons.cities.burdur },
    { id: 16, name: language.commons.cities.bursa },
    { id: 17, name: language.commons.cities.çanakkale },
    { id: 18, name: language.commons.cities.çankırı },
    { id: 19, name: language.commons.cities.çorum },
    { id: 20, name: language.commons.cities.denizli },
    { id: 21, name: language.commons.cities.diyarbakır },
    { id: 22, name: language.commons.cities.edirne },
    { id: 23, name: language.commons.cities.elazığ },
    { id: 24, name: language.commons.cities.erzincan },
    { id: 25, name: language.commons.cities.erzurum },
    { id: 26, name: language.commons.cities.eskişehir },
    { id: 27, name: language.commons.cities.gaziantep },
    { id: 28, name: language.commons.cities.giresun },
    { id: 29, name: language.commons.cities.gümüşhane },
    { id: 30, name: language.commons.cities.hakkari },
    { id: 31, name: language.commons.cities.hatay },
    { id: 32, name: language.commons.cities.ısparta },
    { id: 33, name: language.commons.cities.mersin },
    { id: 34, name: language.commons.cities.istanbul },
    { id: 35, name: language.commons.cities.izmir },
    { id: 36, name: language.commons.cities.kars },
    { id: 37, name: language.commons.cities.kastamonu },
    { id: 38, name: language.commons.cities.kayseri },
    { id: 39, name: language.commons.cities.kırklareli },
    { id: 40, name: language.commons.cities.kırşehir },
    { id: 41, name: language.commons.cities.kocaeli },
    { id: 42, name: language.commons.cities.konya },
    { id: 43, name: language.commons.cities.kütahya },
    { id: 44, name: language.commons.cities.malatya },
    { id: 45, name: language.commons.cities.manisa },
    { id: 46, name: language.commons.cities.kahramanmaraş },
    { id: 47, name: language.commons.cities.mardin },
    { id: 48, name: language.commons.cities.muğla },
    { id: 49, name: language.commons.cities.muş },
    { id: 50, name: language.commons.cities.nevşehir },
    { id: 51, name: language.commons.cities.niğde },
    { id: 52, name: language.commons.cities.ordu },
    { id: 53, name: language.commons.cities.rize },
    { id: 54, name: language.commons.cities.sakarya },
    { id: 55, name: language.commons.cities.samsun },
    { id: 56, name: language.commons.cities.siirt },
    { id: 57, name: language.commons.cities.sinop },
    { id: 58, name: language.commons.cities.sivas },
    { id: 59, name: language.commons.cities.tekirdağ },
    { id: 60, name: language.commons.cities.tokat },
    { id: 61, name: language.commons.cities.trabzon },
    { id: 62, name: language.commons.cities.tunceli },
    { id: 63, name: language.commons.cities.şanlıurfa },
    { id: 64, name: language.commons.cities.uşak },
    { id: 65, name: language.commons.cities.van },
    { id: 66, name: language.commons.cities.yozgat },
    { id: 67, name: language.commons.cities.zonguldak },
    { id: 68, name: language.commons.cities.aksaray },
    { id: 69, name: language.commons.cities.bayburt },
    { id: 70, name: language.commons.cities.karaman },
    { id: 71, name: language.commons.cities.kırıkkale },
    { id: 72, name: language.commons.cities.batman },
    { id: 73, name: language.commons.cities.şırnak },
    { id: 74, name: language.commons.cities.bartın },
    { id: 75, name: language.commons.cities.ardahan },
    { id: 76, name: language.commons.cities.ığdır },
    { id: 77, name: language.commons.cities.yalova },
    { id: 78, name: language.commons.cities.karabük },
    { id: 79, name: language.commons.cities.kilis },
    { id: 80, name: language.commons.cities.osmaniye },
    { id: 81, name: language.commons.cities.düzce }
  ];
  const handleSave = () => {
    let isPrimaryBoolean;
    if (formData.hasMuhaberat === false) {
      isPrimaryBoolean = '0';
    } else {
      isPrimaryBoolean = '1';
    }

    const selectedAvm = mallList.find((mall) => mall.id === formData.mallID);
    if (isEdit) {
      const requestBody = {
        id: editData.id,
        title: selectedAvm.title,
        city: formData.city,
        county: formData.county,
        hasMuhaberat: formData.hasMuhaberat,
      };
      if (transportationToolsWithPrice.length > 0) {
        requestBody.transportationToolsWithPrice = transportationToolsWithPrice;
      }
      api
        .post('/flow/malls-update', requestBody)
        .then((response) => {
          if (response.data.status) {
            setResultMessage('Mağaza başarıyla Düzenlendi!');
            setShowResultMessage(true);
          } else {
            // console.log("error",response)
            setResultMessage('Mağaza Düzenlenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });
      // console.log("requestBody", requestBody);
    } else {
      const requestBody = {
        title: formData.createAvmTitle,
        city: formData.city,
        county: formData.county,
        hasMuhaberat: formData.hasMuhaberat,

      };
      if (formData.avmID.length > 0) {
        requestBody.id = formData.avmID;
      }
      api
        .post('/flow/malls-create', requestBody)
        .then((response) => {
          if (response.data.status) {
            // console.log(response.data)
            setResultMessage('Mağaza başarıyla Eklendi!');
            setShowResultMessage(true);
          } else {
            // console.log("error",response)
            setResultMessage('Mağaza Eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });
    }
  };

  const allFieldsAreValid = () => {
    if (isEdit) {
      if (
        formData.mallID !== '' &&
        formData.city !== '' &&
        formData.county !== '' &&
        formData.avmID !== ''
      ) {
        return true;
      } else return false;
    } else {
      if (
        formData.createAvmTitle !== '' &&
        formData.city !== '' &&
        formData.county !== '' &&
        formData.avmID !== ''

      ) {
        return true;
      } else return false;
    }
  };

  const handleMallChange = async (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      mallID: value,
    }));
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === 'county' || name === 'createAvmTitle' || name === 'avmID') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      return;
    }
  };
  const handleSwitchChange = () => {
    const { name, value } = event.target;

    if (name === 'hasMuhaberat') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: !formData.hasMuhaberat,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleCityChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      city: value,
    }));
  };

  const handleClose = () => {
    setFormData({});
    onClose();
  };


  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setFormData({});
    onClose();
  };

  useEffect(() => {
    if (isEdit) {
      let isPrimaryBoolean;
      if (editData.hasMuhaberat === '1') {
        isPrimaryBoolean = true;
      } else {
        isPrimaryBoolean = false;
      }


      setFormData({
        avmTitle: '',
        mallID: editData.id,
        city: editData.city,
        county: editData.county,
        hasMuhaberat: isPrimaryBoolean,
        isParcelCargo:
          editData?.transportationTools?.includes('Koli/Kargo (Ring)'),
        isSmallTool: editData?.transportationTools?.includes('Küçük Araç'),
        isBigTool: editData?.transportationTools?.includes('Büyük Araç'),
        isSuspendTool: editData?.transportationTools?.includes('Askılı Araç'),
        isPallet: editData?.transportationTools?.includes('Palet'),

        parcelCargoPrice: '',
        smallToolPrice: '',
        bigToolPrice: '',
        suspendToolPrice: '',
        palletPrice: '',

        transportationTool: '',

        transportationCurrency: '',
        isPrimary: false,
      });
    }
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle>{language.avm_list.titles.title}</DialogTitle>

        <DialogContent>
          <>

            <FormControl fullWidth margin="normal">
              {!isEdit && (
                <>
                  <Box sx={{ width: '100%' }}>
                    <Stack gap={'5px'} flexDirection={'row'} alignItems={'flex-start'} >
                      <div><InfoOutlinedIcon style={{ color: 'gray' }} /> </div>
                      <div style={{ marginTop: '0' }}>
                        <Typography color={'gray'}>
                          {language.components.AddEditAvmModal.warning}
                        </Typography>
                      </div>
                    </Stack>
                  </Box>



                </>
              )}
              {isEdit === true ? (
                <>
                  <InputLabel>
                    {language.commons.labels.location_mall}
                  </InputLabel>
                  <Select
                    label={language.commons.labels.location_mall}
                    value={formData.mallID}
                    onChange={handleMallChange}
                    name="mallID"
                    disabled={mallList.length === 0}
                  >
                    {mallList.map((mall, index) => (
                      <MenuItem key={index} value={mall.id}>
                        {mall.title}
                      </MenuItem>
                    ))}
                  </Select>{' '}
                </>
              ) : (
                <>
                  <TextField
                    fullWidth
                    label={language.commons.column.mall_id + '*'}
                    name="avmID"
                    value={formData.avmID}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label={language.commons.column.mall_plaza + '*'}
                    name="createAvmTitle"
                    value={formData.createAvmTitle}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </>
              )}
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>{language.commons.labels.city_name}</InputLabel>
              <Select
                sx={{ width: '100%' }}
                label={language.commons.city + '*'}
                value={formData.city}
                onChange={handleCityChange}
                name="city"
              >
                {cityList.map((city, index) => (
                  <MenuItem key={index} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={language.commons.state + '*'}
              name="county"
              value={formData.county}
              onChange={handleTextFieldChange}
              margin="normal"
            />

            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <Typography>{language.components.AddEditAvmModal.muhaberat}:</Typography>
              <Switch
                checked={formData.hasMuhaberat}
                onChange={handleSwitchChange}
                name="hasMuhaberat"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>

          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {language.commons.back}
          </Button>
          {isEdit === true ? (
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={!allFieldsAreValid()}
            >
              {language.commons.save}
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={!allFieldsAreValid()}
            >
              {language.commons.add}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage} // Fonksiyon olarak geçirildi
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isEdit === true ? (
          <DialogTitle id="alert-dialog-title">
            {language.commons.store_update}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{language.commons.store_add}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>{language.commons.okay}</Button>{' '}
          {/* Fonksiyon olarak geçirildi */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditAvmModal;
