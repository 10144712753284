import React from 'react'
import { useParams } from 'react-router-dom';
import SurveySaveSolutions from 'src/components/survey/SurveySaveSolutions';

const SurveySolutions = () => {


    const { id } = useParams();
 
  return (
    <>
        <SurveySaveSolutions
        formId={id}

        />
    </>
  )
}

export default SurveySolutions