import { useNavigate } from 'react-router-dom';
import Iconify from '../../../components/iconify/Iconify';
import { Button } from '@mui/material';

function CargoPartnerButton({ partnerName, title, type }) {
  const navigate = useNavigate();

  const handleRedirect = (partner) => {
    if (type === 'receive') {
      navigate(`/dashboard/cargo-partner-operations/receive/${partner}`);
    }
    else{
      navigate(`/dashboard/cargo-partner-operations/giving-back/${partner}`);
    }
  };

  return (
    <Button
      sx={{
        width: '100%',
        backgroundColor: '#DC423C',
        color: 'white',
        marginTop: '21px',
        height: '54px',
        justifyContent: 'flex-start',
        display: 'flex',
        gap: '12px',
        paddingLeft: '22px',
        '&:hover': {
          backgroundColor: '#B53832'
        },
      }}
      onClick={() => handleRedirect(partnerName)}
    >
      <Iconify icon={'la:truck'} />{title}
    </Button>
  );
}

export default CargoPartnerButton;
