import React, { createContext, useContext, useState } from 'react';
import tr from '../locale/tr.json'; 

const LocalizationContext = createContext();

export const LocalizationProvider = ({ children }) => {
  const [language, setLanguage] = useState(tr); 

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage); 
  };

  return (
    <LocalizationContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
