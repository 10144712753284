import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { ProtectedRoute } from './sections/auth/ProtectedRoute';
import FinishedJobsPage from './pages/FinishedJobsPage';
import MuhaberatEntryPage from './pages/MuhaberatEntry';
import MuhaberatOutput from './pages/MuhaberatOutput';
import HandsfreePage from './pages/HandsfreePage';
import AllActionsPage from './pages/AllActionsPage';
import RobotListsPage from './pages/RobotListsPage';
import RobotActionsPage from './pages/RobotActionsPage';
import PriceTablePage from './pages/PriceTablePage';
import TechnicalSupportRequests from './pages/TechnicalSupportRequests';
import FormWithoutLogin from './pages/FormWithoutLogin';
import CreateEmployeeApproval from './pages/CreateEmployeeApproval';
import PostDetailPage from './pages/PostDetailPage';
import PointsPage from './pages/PointsPage';
import Checkroom from './pages/CheckRoom';
import PriceCabineTable from './pages/PriceCabineTable';
import ProfilePage from './pages/ProfilePage';
import SupportTickets from './pages/SupportTickets';
import PointsMapPage from './pages/PointsMapPage';
import EditUserForPoint from './pages/EditUserForPoint';
import AvmList from './pages/AvmList';
import FastReport from './pages/FastReport';
import CreateReport from './pages/CreateReport';
import SendPanic from './pages/SendPanic';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import BolPara from './pages/BolPara';
import BolParaApplications from './pages/BolParaApplications';
import CampaignPage from './pages/CampaignPage';
import CreateForm from './pages/CreateForm';
import CargoPartnerOperations from './pages/CargoPartners/CargoPartnerOperations';
import CargoPartnerReceivePage from './pages/CargoPartners/CargoPartnerReceivePage';
import CargoPartnersRefund from './pages/CargoPartners/CargoPartnersRefund';
import CargoPartnerTakeOrderPage from './pages/CargoPartners/CargoPartnerTakeOrderPage';
import CargoPartnersGivingBackPage from './pages/CargoPartners/CargoPartnersGivingBackPage';
import LockerTrackSystem from './pages/LockerTrackSystem';
import SurveySolutions from './pages/SurveySolutions';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: (
            <ProtectedRoute>
              <DashboardAppPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'post-details/:id',
          element: (
            <ProtectedRoute>
              <PostDetailPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'finished-jobs',
          element: (
            <ProtectedRoute>
              <FinishedJobsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'users',
          element: (
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'points',
          element: (
            <ProtectedRoute>
              <PointsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'edit-point-user/:id',
          element: (
            <ProtectedRoute>
              <EditUserForPoint />
            </ProtectedRoute>
          ),
        },
        {
          path: 'monthly-cabine',
          element: (
            <ProtectedRoute>
              <PriceCabineTable />
            </ProtectedRoute>
          ),
        },
        {
          path: 'checkroom',
          element: (
            <ProtectedRoute>
              <Checkroom />
            </ProtectedRoute>
          ),
        },
        {
          path: 'muhaberat-entry',
          element: (
            <ProtectedRoute>
              <MuhaberatEntryPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'muhaberat-output',
          element: (
            <ProtectedRoute>
              <MuhaberatOutput />
            </ProtectedRoute>
          ),
        },
        {
          path: 'handsfree',
          element: (
            <ProtectedRoute>
              <HandsfreePage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'all-posts',
          element: (
            <ProtectedRoute>
              <AllActionsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'robot-lists',
          element: (
            <ProtectedRoute>
              <RobotListsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'robot-actions',
          element: (
            <ProtectedRoute>
              <RobotActionsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'monthly-prices',
          element: (
            <ProtectedRoute>
              <PriceTablePage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'technical-support-requests',
          element: (
            <ProtectedRoute>
              <TechnicalSupportRequests />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-employee-approval',
          element: (
            <ProtectedRoute>
              <CreateEmployeeApproval />
            </ProtectedRoute>
          ),
        },
        {
          path: 'profile',
          element: (
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'support-tickets',
          element: (
            <ProtectedRoute>
              <SupportTickets />
            </ProtectedRoute>
          ),
        },
        {
          path: 'support-tickets/:id',
          element: (
            <ProtectedRoute>
              <SupportTickets />
            </ProtectedRoute>
          ),
        },
        {
          path: 'support-tickets/complete/:id',
          element: (
            <ProtectedRoute>
              <SupportTickets />
            </ProtectedRoute>
          ),
        },
        {
          path: 'bol-para',
          element: (
            <ProtectedRoute>
              <BolPara />
            </ProtectedRoute>
          ),
        },
        {
          path: 'bol-para-applications',
          element: (
            <ProtectedRoute>
              <BolParaApplications />
            </ProtectedRoute>
          ),
        },
        {
          path: 'mall-list',
          element: (
            <ProtectedRoute>
              <AvmList />
            </ProtectedRoute>
          ),
        },
        {
          path: 'fast-report',
          element: (
            <ProtectedRoute>
              <FastReport />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-report',
          element: (
            <ProtectedRoute>
              <CreateReport />
            </ProtectedRoute>
          ),
        },
        {
          path: 'send-panic',
          element: (
            <ProtectedRoute>
              <SendPanic />
            </ProtectedRoute>
          ),
        },
        {
          path: 'campaign',
          element: (
            <ProtectedRoute>
              <CampaignPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-form',
          element: (
            <ProtectedRoute>
              <CreateForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'cargo-partner-operations',
          element: (
            <ProtectedRoute>
              <CargoPartnerOperations />
            </ProtectedRoute>
          ),
        },
        {
          path: 'cargo-partner-operations/receive/:partner',
          element: (
            <ProtectedRoute>
              <CargoPartnerReceivePage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'cargo-partner-operations/ecommerce-refund/:partner',
          element: (
            <ProtectedRoute>
              <CargoPartnersRefund />
            </ProtectedRoute>
          ),
        },
        {
          path: 'cargo-partner-operations/take-order/:partner',
          element: (
            <ProtectedRoute>
              <CargoPartnerTakeOrderPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'cargo-partner-operations/giving-back/:partner',
          element: (
            <ProtectedRoute>
              <CargoPartnersGivingBackPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'locker-track-system',
          element: (
            <ProtectedRoute>
              <LockerTrackSystem />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'form-without-login',
      element: <FormWithoutLogin />,
    },
    {
      path: 'points-map',
      element: <PointsMapPage />,
    },
    {
      path: 'survey/:id',
      element: <SurveySolutions />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
