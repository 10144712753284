import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import OptionsButton from './components/OptionsButton';
import { useLocalization } from 'src/context/LocalizationContext';

export default function CargoPartnerReceivePage() {

    const { language } = useLocalization();

    const navigate = useNavigate();
    const { partner } = useParams();
    let partnerName = ""

    if (partner === "aras-kargo") {
        partnerName = "Aras Kargo"
    }
    
    const handleRedirectToRefund = () => {
        navigate(`/dashboard/cargo-partner-operations/ecommerce-refund/${partner}`);
    };
    const handleRedirectToTakeOrder = () => {
        navigate(`/dashboard/cargo-partner-operations/take-order/${partner}`);
    };

    return (
        <>
            <Helmet>
                <title> {language.cargo_partner_receive.titles.site_title} </title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="column"
                    alignItems="flex-start"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                    {language.cargo_partner_receive.titles.title}
                    </Typography>
                    <p style={{ color: '#D1D1D1', fontSize: '16px', fontWeight:'400' }}>
                        {partnerName} - {language.commons.labels.embezzle}
                    </p>
                    <HorizontalDivider />
                </Stack>

                <OptionsButton onClick={handleRedirectToRefund} title={language.commons.labels.e_commerce}/>
                <OptionsButton onClick={handleRedirectToTakeOrder} title={language.commons.labels.receive_shipment}/>
            </Stack>
        </>
    )
}
