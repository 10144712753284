import React, { useEffect, useRef, useState } from 'react';
import { Backdrop, Card, CircularProgress, FormControl, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { StatusList } from 'src/types/statusTypes';
import DynamicTable from 'src/components/shared/DynamicTable';
import Services from 'src/services/Services';
import { useLocalization } from 'src/context/LocalizationContext';
import { toast } from 'react-toastify';
import moment from 'moment';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import CustomFilter from 'src/components/filter/CustomFilter';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Iconify from 'src/components/iconify';

export default function EpAppPosts() {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const firstInputRef = useRef(null);
  const { language } = useLocalization();
  const parsedUser = JSON.parse(localStorage.getItem('user'));

  const [loading, setLoading] = useState(true);
  const [postList, setPostList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sendBy, setSendBy] = useState("");
  const [willTakenBy, setWillTakenBy] = useState("");
  const [pointTitle, setPointTitle] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);

  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };


  let TABLE_HEAD = [
    { id: 'title', label: language.commons.column.title, format: (value, row) => row.dataJSON.title },
    { id: 'createdDateTime', label: language.commons.column.created_date, format: (value) => formatDate(value) },
    { id: 'sender', label: language.commons.column.sender, format: (value, row) => row.dataJSON.sendBy },
    { id: 'willTakenBy', label: language.commons.column.receiver },
    { id: 'pointTitle', label: language.commons.column.point_name },
    { id: 'cargoStatus', label: language.commons.cargo_status, format: (value, row) => getStatusLabel(+row.status) },
    {
      id: 'action', label: '', alignRight: false, format: (value, row) =>
        <Tooltip title={language.commons.detail}>
          <Iconify style={{ color: '#98A2B3', width: 22, height: 22, cursor: 'pointer' }} icon="bx:detail" onClick={() => window.open(
            `/dashboard/post-details/${row.barcode}`,
            '_blank'
          )} />
        </Tooltip>

    },
  ];

  const getPosts = () => {
    setLoading(true);
    Services.GetEPAppPosts(parsedUser.token)
      .then((res) => {
        setLoading(false)
        if (res.data.status) {
          console.log(res.data.result);
          setPostList(res.data.result);
          setOriginalData(res.data.result);
        }
        else {
          toast.error("Bir hata meydana geldi.")
          setPostList([])
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const formatDate = (date) => {
    const formattedDate = moment.unix(date).format('DD.MM.YYYY');
    return formattedDate;
  };

  useEffect(() => {
    getPosts();
  }, []);

  const getStatusLabel = (statusValue) => {
    const statusObject = StatusList.find((status) => status.id === statusValue);
    return statusObject ? statusObject.label : '';
  };

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStatus(value);
  };

  const getFilteredPostList = () => {
    let filteredPostList;
    if (title || sendBy || willTakenBy || pointTitle || (startDate && endDate)) {
      filteredPostList = originalData.filter((item) => {
        const titleMatch = title
          ? item.dataJSON.title.toLowerCase().includes(title.toLowerCase())
          : true;
        const sendByMatch = sendBy
          ? item.dataJSON.sendBy.toLowerCase().includes(sendBy.toLowerCase())
          : true;
        const willTakenByMatch = willTakenBy
          ? item.willTakenBy.toLowerCase().includes(willTakenBy.toLowerCase())
          : true;
        const pointTitleMatch = pointTitle
          ? item.pointTitle.toLowerCase().includes(pointTitle.toLowerCase())
          : true;
        const dateMatch =
          startDate && endDate
            ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
            moment(startDate).format('DD-MM-YYYY') &&
            moment.unix(item.createdDateTime).format('DD-MM-YYYY') <=
            moment(endDate).format('DD-MM-YYYY')
            : true;

        return (
          titleMatch &&
          sendByMatch &&
          willTakenByMatch &&
          pointTitleMatch &&
          dateMatch
        );
      });
    } else {
      filteredPostList = originalData;
    }

    setPostList(filteredPostList);
  };

  const clearFilter = () => {
    setTitle("");
    setStartDate(null);
    setEndDate(null);
    setSendBy("");
    setWillTakenBy("");
    setPointTitle("");
  }

  return (
    <>
      <Helmet>
        <title>
          {language.ep_app_posts.titles.site_title}
        </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.ep_app_posts.titles.title}
          </Typography>

        </Stack>

        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
          paddingX={2}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Card>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <>
              <Typography sx={{ marginLeft: 1, marginTop: 2, marginBottom: 2 }}>
                {language.all_action_page.titles.post_filter_empty}
              </Typography>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                gap='15px'
              >
                {/* 
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedStatus}
                    input={<OutlinedInput ref={firstInputRef} autoFocus />}
                    onChange={handleChangeStatus}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Kargo Durumu</em>;
                      }
                      return selected.map((x) => x.label).join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {StatusList.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    ).map((x) => (
                      <MenuItem key={x.id} value={x}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    sx={{ flex: 1 }}
                    label={language.commons.column.title}
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    sx={{ flex: 1 }}
                    label={language.commons.column.sender}
                    variant="outlined"
                    value={sendBy}
                    onChange={(e) => setSendBy(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    sx={{ flex: 1 }}
                    label={language.commons.column.receiver}
                    variant="outlined"
                    value={willTakenBy}
                    onChange={(e) => setWillTakenBy(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    sx={{ flex: 1 }}
                    label={language.commons.column.point_name}
                    variant="outlined"
                    value={pointTitle}
                    onChange={(e) => setPointTitle(e.target.value)}
                  />
                </FormControl>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={2}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label={language.commons.labels.start_date}
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label={language.commons.labels.end_date}
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </LocalizationProvider>
                </Stack>


                <Stack flexDirection="row" justifyContent="center" gap='15px' marginTop='25px' alignItems='center' display="flex" width='100%'>
                  <ClearButton onClick={clearFilter} />
                  <div
                    style={{
                      height: '20px',
                      width: '1px',
                      backgroundColor: '#EAECF0',
                      marginRight: '8px'
                    }}
                  ></div>
                  <FilterButton onClick={getFilteredPostList} />
                </Stack>

              </Stack>
            </>

          </CustomFilter>
          {loading ? (
            <Backdrop sx={{ color: '#fff', zIndex: 2, height: '100%' }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <DynamicTable
              data={postList}
              headers={TABLE_HEAD}
              isOrdered={true}
              isMobile={isMobile}
              handleModal={handleModal}
              handleCloseModal={handleCloseModal}
              initialOrder={"createdDateTime"}
              initalOrderDirection={"desc"}
            />
          )}


        </Card>
      </Stack>


    </>
  );
}
