import { Box } from '@mui/material';
import React from 'react';
import axios from 'axios';
import Services from 'src/services/Services';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '', userIP: '' };
  }

  static getDerivedStateFromError(error) {
    // Hata olduğunda state'i güncelle
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const currentURL = window.location.href;
    const userAgent = navigator.userAgent; // Kullanıcı aracını al
    const screenWidth = window.screen.width; // Ekran genişliği
    const screenHeight = window.screen.height; // Ekran yüksekliği
    // IP adresini almak için getData fonksiyonunu çağırıyoruz
    this.getData().then((ipAddress) => {
      const message = "<b>Adı Soyadı:</b> " + parsedUser.name + " " + parsedUser.lastname + "\n" +
        "<b>UserID:</b> " + parsedUser.userID + "\n" +
        "<b>IP Adresi:</b> " + ipAddress + "\n" +
        "<b>User Agent:</b> " + userAgent + "\n" + // Kullanıcı aracını ekle
        "<b>Ekran Çözünürlüğü:</b> " + screenWidth + " x " + screenHeight + "\n" + // Çözünürlüğü ekle
        "<b>URL:</b> " + currentURL + "\n\n" + error.toString() + "\n" + errorInfo.componentStack;

      Services.GetToken()
        .then((response) => {
          Services.CreateTicket(response.data.result.token, "Sistem Hatası", message, "Report Bug", [], "sistem@easypoint.com.tr")
            .then(() => {
              if (error.toString().includes("startPositions.at is not a function")) {
                this.setState({
                  errorMessage: (
                    <>
                      Lütfen tarayıcınızı güncelleyiniz.<br />
                      <p style={{ color: '#475467', fontSize: '14px', fontWeight: '400', textAlign: 'left' }}>Google Chrome kullanıyorsanız tarayıcınızın versiyonu minimum : '92' olmalıdır.</p>
                      <p style={{ color: '#475467', fontSize: '14px', fontWeight: '400', textAlign: 'left' }}>Safari kullanıyorsanız tarayıcınızın versiyonu minimum : '15.4' olmalıdır.</p>
                    </>
                  )
                });
              }
            });
        })
        .catch((err) => console.error("Ticket oluşturulamadı", err));
    }).catch((error) => {
      console.error("IP adresi alınamadı:", error);
    });
  }

  // IP adresini almak için ipify API'sini kullanan fonksiyon
  getData = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      this.setState({ userIP: res.data.ip });
      return res.data.ip;
    } catch (error) {
      console.error("IP adresi alınırken hata oluştu", error);
      throw error; // IP alınamazsa hata fırlat
    }
  }

  handleGoHome = () => {
    // Kullanıcıyı anasayfaya yönlendir
    window.location.href = '/dashboard';
  }

  render() {
    if (this.state.hasError) {
      // Eğer özel bir hata mesajı varsa onu göster, yoksa genel bir mesaj göster
      return (
        <div style={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <div style={{ width: '75%', height: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

            <div style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', display: 'flex', width: '35%' }}>
              <Box
                component="img"
                src="/assets/redLogo.png"
                sx={{ width: { xs: '68px', sm: '68px' }, height: 'auto', marginBottom: '125px' }}
              />
              <span style={{ color: '#DA3831', fontSize: '16px', fontWeight: '600', textAlign: 'left', fontFamily: 'Inter' }}>Sistem hatası</span>
              <span style={{ color: '#101828', fontSize: '60px', fontWeight: '600', textAlign: 'left', fontFamily: 'Inter' }}>Sayfa şuanda görüntülenemiyor.</span>
              <p style={{ color: '#475467', fontSize: '20px', fontWeight: '400', textAlign: 'left' }}>
                {this.state.errorMessage || "Sistem şuan yanıt vermemektedir. Lütfen daha sonra tekrar deneyin."}
              </p>
              <button
                onClick={this.handleGoHome}
                style={{
                  marginTop: '20px',
                  padding: '10px 20px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  backgroundColor: '#DA3831',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px'
                }}
              >
                Anasayfaya Dön
              </button>
            </div>

            <div style={{ width: '65%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Box
                component="img"
                src="/assets/ErrorPage.png"
              />
            </div>

          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
