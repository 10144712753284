import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Slider, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import api from 'src/services/BaseService';
import Services from 'src/services/Services';
import { tokenService } from 'src/services';
import resim from '../../assets/images/logo.png';
import { toast } from 'react-toastify';
import { useLocalization } from 'src/context/LocalizationContext';

const SurveySaveSolutions = ({ formId }) => {
    const { language } = useLocalization();
    const [surveyList, setSurveyList] = useState();
    const [answers, setAnswers] = useState({});
    const [token, setToken] = useState("");
    const [isLogged, setIsLogged] = useState();

    function getSurvey() {
        api
            .post('flow/get-surveys', { surveyID: formId }, {
                headers: {
                    Authorization: `Bearer ${token}`, // Bearer token başlığı ile token'ı gönderiyoruz
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                if (response.data.status) {
                    setSurveyList(response.data.result[0]);
                    //setOriginalData(response.data.result);
                    //http://localhost:3006/survey/60973513821002497448
                } else {
                    console.log("response-error", response);
                }
            })
            .catch((error) => console.log(error.message));
    }

    useEffect(() => {
        const user = tokenService.getUser();
        if (user) {
            setIsLogged(true);
            setToken(user.token);
            getSurvey()
        } else {
            Services.GetToken().then((res) => {
                if (res.data.status) {
                    const { token } = res.data.result;
                    Services.GetSurvey(token, formId).then(res => {
                        setSurveyList(res.data.result[0]);
                        //console.log("res", res);
                    })
                    setToken(token);
                }
            }).catch((error) => console.log("Error getting token:", error));
        }
    }, []);

    //Token değiştiğinde anketi al
    // useEffect(() => {
    //     if (token) {
    //         getSurvey();
    //     }
    // }, [token]);

    const handleAnswerChange = (questionIndex, answer, isChecked = null) => {
        setAnswers((prevAnswers) => {
            const updatedAnswers = { ...prevAnswers };

            if (isChecked !== null) {
                // Checkbox için
                if (isChecked) {
                    // Eğer işaretlendiyse, ekle
                    if (!Array.isArray(updatedAnswers[questionIndex])) {
                        updatedAnswers[questionIndex] = [];
                    }
                    updatedAnswers[questionIndex].push(answer);
                } else {
                    // Eğer işareti kaldırıldıysa, çıkar
                    updatedAnswers[questionIndex] = updatedAnswers[questionIndex].filter(
                        (selectedAnswer) => selectedAnswer !== answer
                    );
                }
            } else {
                // Diğer soru türleri için
                updatedAnswers[questionIndex] = answer;
            }

            return updatedAnswers;
        });
    };

    const handleSave = async () => {
        const sanitizeHTML = (html) => {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.innerText || doc.body.textContent;
        };
        const payload = {
            user: {
                userID: isLogged ? tokenService.getUser().userID : false, // Giriş yapmamışsa false
                name: isLogged ? tokenService.getUser().name : "Anonim", // Giriş yapmamışsa anonim
                lastname: isLogged ? tokenService.getUser().lastname : "Lastname Anonim"
            },
            surveyID: formId,
            response: surveyList.questions.map((question, index) => ({
                question: sanitizeHTML(question.label),
                type: question.type,
                canSelectOneMore: question.canSelectOneMore,
                options: question.options || false,
                response: answers[index] || null,
            })),
        };

        console.log("payload", payload);
        try {
            const response = await api.post(`flow/survey-save-response`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.status) {
                toast.success("Cevaplar başarıyla kaydedildi!");
                setAnswers({});
            } else {
                console.log("Kaydetme hatası:", response);
            }
        } catch (error) {
            console.error("Cevap kaydetme hatası:", error.message);
        }
    };

    const isFormValid = () => {
        if (!surveyList?.questions || surveyList.questions.length === 0) return false;

        return surveyList.questions.every((question, index) => {
            const answer = answers[index];

            if (question.type === "short_answer") {
                return answer && answer.trim() !== "";
            }
            if (question.type === "multi_choice" && !question.canSelectOneMore) {
                return !!answer;
            }
            if (question.type === "multi_choice" && question.canSelectOneMore) {
                return Array.isArray(answer) && answer.length > 0;
            }
            if (question.type === "rating") {
                return !!answer;
            }
            return true;
        });
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };
    return (
        <Box >
            <Stack justifyContent={'left'} flexDirection="row" sx={{ boxShadow: '2px 1px 4px 0px rgba(0, 0, 0, 0.1)', background: 'white', padding: '15px', marginBottom: '20px' }} >
                <Box sx={{ marginLeft: '50px' }}>
                    <img height="70" width="70" src={resim} alt="Easypoint Logo" />
                </Box>
            </Stack>

            <Container
                maxWidth='lg'
                sx={{
                    padding: 3,
                    backgroundColor: 'white',
                    borderRadius: 3,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                <Stack sx={{ borderBottom: '1px solid #E5E5E5', marginBottom: '45px' }}>
                    <Typography variant="h4" gutterBottom textAlign={'center'}>
                        {surveyList?.title || language.commons.surveys.survey_loading}
                    </Typography>
                </Stack>

                <Stack sx={{ paddingX: 5 }}>
                    <Grid container spacing={5}>
                        {surveyList?.questions && surveyList.questions.length > 0 ? (
                            surveyList.questions.map((question, index) => {
                                const minValue = question.options[0]?.minValue;
                                const maxValue = question.options[0]?.maxValue;
                                const ratings = Array.from({ length: maxValue - minValue + 1 }, (_, index) => minValue + index);
                                return (
                                    <Grid item xs={12} md={6} key={index} >
                                        <Box sx={{ marginBottom: 3, flex: 1 }}>
                                            <Stack sx={{ paddingBottom: '15px' }}>
                                                <Typography variant="p" >
                                                    {index + 1}. {stripHtmlTags(question.label)}
                                                </Typography>
                                                {/* Sadece rating tipindeki sorular için min ve max açıklamaları */}
                                                {question.type === 'rating' && (
                                                    <Typography variant="span" sx={{ ml: 1, color: 'text.secondary' }}>
                                                        ({ratings[0]} : {question.options[0].minContent}  /  {ratings[ratings.length - 1]} : {question.options[0].maxContent})
                                                    </Typography>
                                                )}

                                            </Stack>
                                            <Stack sx={{ paddingLeft: '10px', paddingTop: '20px' }}>

                                                {/* Open-ended (text input) */}
                                                {question.type === "short_answer" && (
                                                    <TextField
                                                        label={language.commons.labels.answer_pls}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={answers[index] || ''}
                                                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                                                    />
                                                )}

                                                {/* Multiple-choice (radio buttons) */}
                                                {question.type === "multi_choice" && !question.canSelectOneMore && (
                                                    <RadioGroup
                                                        value={answers[index] || ''}
                                                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                                                    >
                                                        {question.options.map((option, i) => (
                                                            <FormControlLabel key={i} value={option} control={<Radio />} label={option} />
                                                        ))}
                                                    </RadioGroup>
                                                )}
                                                {/* Multiple-choice (checkbox) */}
                                                {question.type === "multi_choice" && question.canSelectOneMore && (
                                                    <FormGroup sx={{ flexDirection: 'column' }}>
                                                        {question.options.map((option, optionIndex) => (
                                                            <FormControlLabel
                                                                key={optionIndex}
                                                                control={<Checkbox
                                                                    onChange={(e) => handleAnswerChange(index, option, e.target.checked)}
                                                                    color="primary" />}
                                                                label={
                                                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                                        {option}
                                                                    </Typography>
                                                                }
                                                                value={option}
                                                                name={`question_${index}_${optionIndex}`}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                )}
                                                {/* rating  */}
                                                {
                                                    question.type === 'rating' &&
                                                    <Stack spacing={2}>
                                                        <Stack direction="row" spacing={2} flexWrap="wrap" alignItems="center">
                                                            {ratings.map((rating, ratingIndex) => (
                                                                <React.Fragment key={ratingIndex}>


                                                                    {/* Buton */}
                                                                    <Stack alignItems="center">
                                                                        <Button
                                                                            variant="outlined"
                                                                            onClick={() => handleAnswerChange(index, rating)}
                                                                            sx={{
                                                                                minWidth: '30px',
                                                                                minHeight: '30px',
                                                                                bgcolor: answers[index] === rating ? '#da3831' : 'transparent',
                                                                                color: answers[index] === rating ? 'white' : '#000',
                                                                                padding: '2px 2px',
                                                                                transition: 'background-color 0.3s, transform 0.2s',
                                                                                '&:hover': {
                                                                                    bgcolor: '#da3831',
                                                                                    transform: 'scale(1.05)',
                                                                                },
                                                                            }}
                                                                        >
                                                                            {rating}
                                                                        </Button>
                                                                    </Stack>

                                                                </React.Fragment>
                                                            ))}
                                                        </Stack>

                                                    </Stack>

                                                }
                                            </Stack>
                                        </Box>
                                    </Grid>
                                )
                            }

                            )
                        ) : (
                            <Typography>{language.commons.surveys.no_question}</Typography>
                        )}
                    </Grid>
                </Stack>
                <Stack alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={!isFormValid()}
                        sx={{ marginTop: 3, width: 150 }}
                    >
                        {language.commons.send}
                    </Button>
                </Stack>

            </Container>
        </Box>
    )
}

export default SurveySaveSolutions