export const StatusList = [
  {
    label: "Tamamlandı",
    id: 1
  },
  {
    label: "İptal",
    id: 2
  },
  {
    label: "Bekleniyor",
    id: 3
  },
  {
    label: "Taşıma Görevlisi Zimmetinde",
    id: 4
  },
  {
    label: "Şubede",
    id: 5
  },
  {
    label: "Dolapta",
    id: 6
  },
  {
    label: "Transfer Ediliyor",
    id: 7
  },
  {
    label: "Taşıma Görevlisi Atandı",
    id: 8
  },
  {
    label: "İade Edilecek",
    id: 9
  },
  {
    label: "Concierge Kuryeye Teslim Edildi",
    id: 10
  },
  {
    label: "Depoda Teslim Alınmayı Bekliyor",
    id: 11
  },
  {
    label: "Mağazada Teslim Alınmayı Bekliyor",
    id: 12
  },
  {
    label: "Ofiste Teslim Alınmayı Bekliyor",
    id: 13
  },
  {
    label: "Mal Kabulde Teslim Edilmek Üzere Bekliyor",
    id: 14
  },
  {
    label: "Çoklu gönderim başladı",
    id: 15
  },
  {
    label: "Trendyola İade Edildi",
    id: 16
  },
  {
    label: "Getir Kuryesine Teslim Edildi",
    id: 17
  },
  {
    label: "Mağazadan Teslim Alındı Onayı Bekleniyor",
    id: 18
  },
  {
    label: "İptal Edildi, Mağaza Geri Götürülüyor",
    id: 19
  },
  {
    label: "İptal Edildi, Mağaza Geri Götürülecek",
    id: 20
  },
  {
    label: "Hepsiburadaya İade Edildi",
    id: 21
  },
  {
    label: "Hepsiburadaya Teslim Edildi",
    id: 22
  },
  {
    label: "Emirden İptal",
    id: 23
  },
  {
    label: "Zimmetten İptal",
    id: 24
  },
  {
    label: "Mal Kabulden İptal",
    id: 25
  },
  {
    label: "Robottan Onay Bekleniyor",
    id: 26
  },
  {
    label: "Robota Teslim Edilecek",
    id: 27
  },
  {
    label: "Robot Üzerinde",
    id: 28
  },
  {
    label: "Robot Üzerinden İptal",
    id: 29
  },
  {
    label: "Robot Uygun Değil",
    id: 30
  },
  {
    label: "Muhaberatta Kargo Firmasına Verilmek Üzere Bekliyor",
    id: 31
  },
  {
    label: "Parçalandı",
    id: 32
  },
  {
    label: "Hepsijet Teslim Edecek",
    id: 33
  },
  {
    label: "Kayıp",
    id: 34
  },
  {
    label: "Geç Gönderim",
    id: 35
  },
  {
    label: "Dolandırcılık",
    id: 36
  },
  {
    label: "Paket Zarar Görmüş",
    id: 37
  },
  {
    label: "Hepsijete İade Edildi",
    id: 38
  },
  {
    label: "Gönderi Yolda",
    id: 39
  },
  {
    label: "Aras Kargoya İade Edildi",
    id: 40
  },
  {
    label: "Sürat Kargo Teslim Edecek",
    id: 41
  },
  {
    label: "Kargoist Teslim Edecek",
    id: 42
  }
];

