import { Card, Container, Stack, Typography, Button, FormControl, Select, MenuItem, InputLabel, TextField, TextareaAutosize, Switch, OutlinedInput } from '@mui/material';
import React, {  useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
const CreatingFormsView = () => {
    const [formContent, setFormContent] = useState([]);
    const [onEdit, setOnEdit] = useState(false);
    const [textField, setTextField] = useState('');
    const [editedField, setEditedField] = useState('');
    const [previewMode, setPreviewMode] = useState(false);

    const addQuestion = () => {

        const field = {
            "name": `question_${formContent?.length}`,
            "label": "Untitle Question",
            "question_type": "short_answer",
            "list": [],
            "selectedValue": ''
        }
        setFormContent([...formContent, field]);

    }



    const editField = (fieldName, fieldLabel) => {
        const formFields = [...formContent];
        const fieldIndex = formFields.findIndex(f => f.name === fieldName);
        if (fieldIndex > -1) {
            formFields[fieldIndex].label = fieldLabel;
            setFormContent(formFields)
        }
    }
    const editFieldType = (fieldName, fieldLabel) => {
        const formFields = [...formContent];
        const fieldIndex = formFields.findIndex(f => f.name === fieldName);
        if (fieldIndex > -1) {
            formFields[fieldIndex].question_type = fieldLabel;
            setFormContent(formFields)
        }
    }
    const addFieldOption = (fieldName, option) => {
        const formFields = [...formContent];
        const fieldIndex = formFields.findIndex(f => f.name === fieldName);
        if (fieldIndex > -1) {
            if (option && option !== '') {
                formFields[fieldIndex].list.push(option);
                setFormContent(formFields);
                setTextField("");
            }

        }


    }
    const removeFieldOption = (fieldName) => {
        const formFields = [...formContent];
        const fieldIndex = formFields.findIndex(f => f.name === fieldName);

        if (fieldIndex > -1) {

            formFields.splice(fieldIndex, 1);
            setFormContent(formFields);
        }
    };


    const handleSwitchChange = () => {
        setPreviewMode(!previewMode);
    }

    return (
        <Container>
            <Stack alignItems='end'>
                <Typography>Preview Mode:</Typography>
                <Switch
                    checked={previewMode}
                    onChange={handleSwitchChange}
                    name="previewMode"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Stack>
            {previewMode === false &&
                <Stack spacing={5}>
                    <Stack sx={{ boxShadow: '2px 1px 4px 0px rgba(0, 0, 0, 0.1)', borderTop: '7px solid red', borderRadius: '8px', background: 'white', padding: '15px' }} >
                        <Typography variant="h2">Form Maker</Typography>
                        <Typography variant="h3">From Title</Typography>
                    </Stack>
                    <Card sx={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', gap: '25px' }} >
                        {
                            formContent?.map((field, index) => (
                                <Stack flexDirection="row" key={index}>
                                    <Stack padding={3} sx={{ boxShadow: '2px 1px 4px 0px rgba(0, 0, 0, 0.1)', flex: 1, display: 'flex', gap: '15px', backgroundColor: 'white' }} variant="outlined">
                                        <Stack sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >
                                            <Stack key={field.name} sx={{ display: 'block' }} >
                                                {
                                                    onEdit && (editedField === field.name) ?
                                                        <TextField
                                                            onBlur={() => { setOnEdit(false); setEditedField("") }}
                                                            onChange={(e) => editField(field.name, e.target.value)}
                                                            id="on_edit"
                                                            name="on_edit"
                                                            type="text"
                                                            label={field.label}
                                                            fullWidth
                                                            value={field.label}
                                                        // value={barcode}
                                                        />
                                                        :
                                                        <InputLabel onClick={() => { setOnEdit(true); setEditedField(field.name) }} id="qesiton-select-label">{field.label}</InputLabel>

                                                }
                                            </Stack>
                                            <Stack>
                                                <FormControl fullWidth>
                                                    <InputLabel id="priority-store-label">Questions</InputLabel>
                                                    <Select
                                                        onChange={(e) => editFieldType(field.name, e.target.value)}
                                                        labelId="question--select-label"
                                                        id="question-select"
                                                        label="Questions"
                                                        defaultValue='short_answer'
                                                        input={<OutlinedInput label="Platform" />}
                                                    //  value={formSelectValiable}
                                                    //  onChange={handleChange}
                                                    >
                                                        <MenuItem value='short_answer'>Short Answer</MenuItem>
                                                        <MenuItem value='paragraph'>Paragraph</MenuItem>
                                                        <MenuItem value='multi_choice'>MultiChoice</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>

                                        </Stack>

                                        <Stack marginY={2}>
                                            {
                                                field.question_type === 'short_answer' && <TextField
                                                    id="short_answer"
                                                    name="short_answer"
                                                    type="text"
                                                    label={field.label}
                                                    fullWidth
                                                // value={barcode}
                                                />
                                            }
                                            {
                                                field.question_type === 'paragraph' && <TextareaAutosize aria-label="minimum height" minRows={3} placeholder={field.label} />
                                            }
                                            {
                                                field.question_type === 'multi_choice' &&
                                                <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="priority-store-label">{field.name}</InputLabel>
                                                        <Select
                                                            labelId="multi_choice--select-label"
                                                            id="multi_choice-select"
                                                            label="MultiChoice"
                                                            defaultValue={field.list[0]}
                                                        // value={field.selectedValue}
                                                        // onChange={(e) => handleSelectChange(field.name, e.target.value)}
                                                        >
                                                            {
                                                                field.list.map(item =>
                                                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <Stack>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                onChange={(e) => setTextField(e.target.value)}
                                                                value={textField}
                                                                id="add_option"
                                                                name="add_option"
                                                                type="text"
                                                                label="add an option"
                                                                fullWidth

                                                            />
                                                            <Button onClick={() => addFieldOption(field.name, textField)}>Add</Button>
                                                        </FormControl>
                                                    </Stack>
                                                </Stack>
                                            }
                                        </Stack>
                                    </Stack>
                                    <Stack justifyContent="center" sx={{ backgroundColor: 'white' }}>
                                        <Button onClick={() => removeFieldOption(field.name)}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Stack>
                                </Stack>


                            )
                            )
                        }
                        <Stack sx={{ position: 'relative' }}>
                            <Stack flex="d-flex" justifyContent="center" sx={{ poisition: 'absolute' }}>
                                <Button onClick={() => addQuestion()} variant='contained' sx={{ height: '60px' }} >Soru Ekle</Button>
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>
            }

            {/* Preview Modal  */}
            {previewMode === true &&
                <>
                    <Stack>
                        <Typography variant="h2">Form Preview</Typography>
                        <Typography variant="h3">From Title</Typography>
                    </Stack>
                    <Card sx={{ boxShadow: '2px 1px 4px 0px rgba(0, 0, 0, 0.1)' }} variant="outlined">
                        {
                            formContent?.map((field, index) => (
                                <Stack padding={3} key={index}>
                                    <Stack sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                                        <Stack key={field.name} sx={{ display: 'block' }}>

                                            <InputLabel onClick={() => setOnEdit(true)} id="qesiton-select-label">{field.label}</InputLabel>

                                        </Stack>


                                    </Stack>
                                    <Stack marginY={2}>
                                        {
                                            field.question_type === 'short_answer' && <TextField
                                                id="short_answer"
                                                name="short_answer"
                                                type="text"
                                                label={field.label}
                                                fullWidth
                                            // value={barcode}
                                            />
                                        }
                                        {
                                            field.question_type === 'paragraph' && <TextareaAutosize aria-label="minimum height" minRows={3} placeholder={field.label} />
                                        }
                                        {
                                            field.question_type === 'multi_choice' &&
                                            <FormControl fullWidth>
                                                <InputLabel id="priority-store-label">{field.label}</InputLabel>
                                                <Select
                                                    labelId="multi_choice--select-label"
                                                    id="multi_choice-select"
                                                    label="MultiChoice"
                                                    defaultValue={field.list[0]}
                                                // onChange={handleChange}
                                                >
                                                    {
                                                        field.list.map(item =>
                                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                                        )
                                                    }


                                                </Select>
                                            </FormControl>
                                        }
                                    </Stack>
                                </Stack>

                            )
                            )
                        }

                    </Card>
                </>
            }

        </Container>

    )
}

export default CreatingFormsView
