import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import RatingChart from './RatingChart';
import MultiChoiceChart from './MultiChoiceChart';
import ShortAnswerChart from './ShortAnswerChart';
import { useLocalization } from 'src/context/LocalizationContext';


const QuestionAnalyzer = ({ responses }) => {
    const { language } = useLocalization();
    //console.log("response", responses)
    const [summary, setSummary] = useState({
        multi_choice: {},
        rating: {},
        short_answer: {}
    });

    const calculateSummary = () => {
        const groupedSummary = {
            multi_choice: {},
            rating: {},
            short_answer: {}
        };

        responses.map((survey) => {
            //console.log("survey", survey)
            survey.response.map((question) => {
                const { type, question: questionText, response, options, maxRating } = question;

                // Burada null ve undefined değerlerini yok sayıyoruz
                if (response === null || response === undefined) {
                    return;
                }
                // Kısa cevap soruları için

                if (type === "short_answer") {

                    if (!groupedSummary.short_answer[questionText]) {
                        groupedSummary.short_answer[questionText] = {
                            totalResponses: 0,
                            details: {}
                        };
                    }
                    groupedSummary.short_answer[questionText].totalResponses += 1;
                    groupedSummary.short_answer[questionText].details[response] =
                        (groupedSummary.short_answer[questionText].details[response] || 0) + 1;

                } else if (type === "multi_choice") {
                    if (!groupedSummary.multi_choice[questionText]) {
                        groupedSummary.multi_choice[questionText] = {
                            totalResponses: 0,
                            details: {}
                        };

                        options.forEach(option => {
                            groupedSummary.multi_choice[questionText].details[option] = 0;
                        });
                    }
                    groupedSummary.multi_choice[questionText].totalResponses += 1;
                    groupedSummary.multi_choice[questionText].details[response] =
                        (groupedSummary.multi_choice[questionText].details[response] || 0) + 1;

                } else if (type === "rating") {
                    if (!groupedSummary.rating[questionText]) {
                        groupedSummary.rating[questionText] = {
                            totalResponses: 0,
                            details: {}
                        };

                        const ratingRange = maxRating || 5; // maxRating verilmemişse varsayılan olarak 5 kabul edelim
                        for (let i = 1; i <= ratingRange; i++) {
                            groupedSummary.rating[questionText].details[i] = 0;
                        }
                    }
                    groupedSummary.rating[questionText].totalResponses += 1;
                    groupedSummary.rating[questionText].details[response] =
                        (groupedSummary.rating[questionText].details[response] || 0) + 1;
                }
            });
        });

        setSummary(groupedSummary);
    };

    useEffect(() => {
        calculateSummary();
    }, [responses]);


    return (
        <Box sx={{ paddingLeft: '15px' }}>
            {/* Çoktan Seçmeli Sorular */}
            <Box>
                <h3>{language.commons.surveys.multi_choice}</h3>
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 5, sm: 15 }} paddingX={3}>
                    <MultiChoiceChart
                        summary={summary}
                    />
                </Stack>
            </Box>
            {/* Derecelendirme Soruları */}
            <Box>
                <Box>
                    <h3>{language.commons.surveys.rating}</h3>
                    <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 5, sm: 15 }} paddingX={3}>
                        <RatingChart
                            summary={summary.rating}
                        />
                    </Stack>
                </Box>
            </Box>
            {/* Kısa Cevap Soruları */}
            <Stack>
                <h3>{language.commons.surveys.short_answer}</h3>
                <Stack flexDirection="row" gap={20}>
                    <ShortAnswerChart
                        summary={summary}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};

export default QuestionAnalyzer;
